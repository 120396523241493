import moment from "moment";
import countries from "./countries";
import {
  PLAN_FREE,
  GENDER_MALE,
  PRODUCT_TREE,
  TEST_TYPE_TT,
  TEST_TYPE_PD,
  TEST_TYPE_CS,
  GENDER_FEMALE,
  GENDER_UNKNOWN,
  MARKER_OFFSET_SIZE,
  SUBSCRIPTION_ACTIVE,
  PRODUCT_CARD_SORTING,
  PRODUCT_PARTICIPATORY,
} from "../actions/constants";
import { TREE_TESTING, PARTICIPATORY, CARDSORTING_ICON, DASHBOARD, TEST_DETAILS, ITERATIVE } from "../utills/icons";

// Generate Unique ID
export const uniqueID = () => Math.random().toString(36).substring(2, 10);

export const copyToClipboard = (elementRef) => {
  if (elementRef) {
    elementRef.current.select();
    document.execCommand("copy");
  }
};

// Convert \n into <br>
export const newLineToBr = (string) => string.replace(/\n/g, "<br />");

// Marker Names from A-Z
export const markerNames = "abcdefghijklmnopqrstuvwxyz".toUpperCase().split("");

// Marker Co-ordinates
export const getMarkerCoordinates = (imgInfo = null, left, top) => {
  if (imgInfo) {
    top = (top * imgInfo.offsetWidth) / 100;
    left = (left * imgInfo.offsetHeight) / 100;
  }

  top -= MARKER_OFFSET_SIZE;
  left -= MARKER_OFFSET_SIZE;

  return { top, left };
};

// USER Gender Text
export const getGender = (gender) => {
  const genders = { [GENDER_UNKNOWN]: "Unknown", [GENDER_FEMALE]: "Female", [GENDER_MALE]: "Male" };
  return genders[gender];
};

// USER Nationality
export const getNationality = (nationality) => {
  const contry = countries.find((cntry) => cntry.key === nationality);
  return contry.text;
};

// Product Name
export const getProductName = (product) => {
  product = product || "recent_tests";
  const products = {
    [PRODUCT_TREE]: "Tree Testing",
    [PRODUCT_PARTICIPATORY]: "Participatory Design",
    [PRODUCT_CARD_SORTING]: "Card Sorting",
    tools: "All Tools",
    subscriptions: "Billing",
    recent_tests: "All Tests",
    settings: "Settings",
  };

  return product && products[product] ? products[product] : "";
};

// Product Image
export const getProductImage = (product) => {
  product = product || "recent_tests";
  const products = {
    [PRODUCT_TREE]: TREE_TESTING,
    [PRODUCT_PARTICIPATORY]: PARTICIPATORY,
    [PRODUCT_CARD_SORTING]: CARDSORTING_ICON,
    tools: DASHBOARD,
    subscriptions: ITERATIVE,
    recent_tests: TEST_DETAILS,
    settings: DASHBOARD,
  };

  return product && products[product] ? products[product] : "";
};

export const getTestTypeIcon = (test) => {
  if (test?.type === TEST_TYPE_TT) return TREE_TESTING;
  else if (test?.type === TEST_TYPE_PD) return PARTICIPATORY;
  else return CARDSORTING_ICON;
};

// Get Stripe Common Error Messages
export const getStripeErrorMsg = (code, message, type) => {
  const errorMsgs = {
    generalMsg: "An error occurred while processing your card. Try again in a little bit.",
    payment_intent_incompatible_payment_method: "Invalid payment method, Please try again with other card.",
    payment_intent_authentication_failure:
      "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
  };
  return errorMsgs[code] ? errorMsgs[code] : message;
};

// Get Active Plan
export const getActivePlan = (allPlans, product) => {
  // product = product === PRODUCT_CARD_SORTING ? PRODUCT_TREE : product;
  let activePlan = allPlans.find((item) => item.product === product && item.status === SUBSCRIPTION_ACTIVE);
  return activePlan || allPlans.find((item) => item.product === product && item.plans.type === PLAN_FREE);
};

// Checks if plan is free
export const isFreePlan = (item) => item.plans.type === PLAN_FREE;

// Compare Date
const compareDate = (date) => moment.utc() > moment.utc(date);

// Allow Test Creation or not
export const isPlanExpired = (subscriptions, product) => {
  if (!subscriptions || subscriptions.length === 0) return true;

  // if (
  //   product === TEST_TYPE_TT ||
  //   product === TEST_TYPE_PD ||
  //   product === PRODUCT_TREE ||
  //   product === PRODUCT_PARTICIPATORY
  // )
  //   return true;

  if (product && product !== undefined) {
    let productType;
    if (isNaN(product)) productType = product;
    else if (product === TEST_TYPE_TT) productType = PRODUCT_TREE;
    else if (product === TEST_TYPE_PD) productType = PRODUCT_PARTICIPATORY;
    else if (product === TEST_TYPE_CS) productType = PRODUCT_CARD_SORTING;

    const productPlan = getActivePlan(subscriptions, productType);
    return compareDate(productPlan.end_date);
  }

  const treeTest = getActivePlan(subscriptions, PRODUCT_TREE);
  // const participateTest = getActivePlan(subscriptions, PRODUCT_PARTICIPATORY);
  const cardTest = getActivePlan(subscriptions, PRODUCT_CARD_SORTING);

  // return compareDate(treeTest.end_date) && compareDate(participateTest.end_date) && compareDate(cardTest.end_date);
  return compareDate(treeTest.end_date) && compareDate(cardTest.end_date);
};

// Check for Free Trial
export const isFreeTrial = (subscriptions, product) => {
  getPlanExpireTime(subscriptions, product);
  if (!subscriptions || subscriptions.length === 0) return true;
  return isFreePlan(getActivePlan(subscriptions, product));
};

// Get Plan Expire Time
export const getPlanExpireTime = (subscriptions, product) => {
  if (!subscriptions || subscriptions.length === 0) return true;
  const plan = getActivePlan(subscriptions, product);
  return moment.utc(plan.end_date).diff(moment.utc(), "days");
};

// Shuffle array elements
export const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

// Array sort by key
export const sortByKey = (array, sortKey) => array.sort((a, b) => a[sortKey] - b[sortKey]);

// Mean
export const mean = (numbers) => {
  let total = 0,
    i;
  for (i = 0; i < numbers.length; i += 1) {
    total += numbers[i];
  }
  return total / numbers.length;
};

//  Mode
export const mode = (numbers) => {
  let modes = [],
    count = [],
    i,
    number,
    maxIndex = 0;

  for (i = 0; i < numbers.length; i += 1) {
    number = numbers[i];
    count[number] = (count[number] || 0) + 1;
    if (count[number] > maxIndex) {
      maxIndex = count[number];
    }
  }

  for (i in count)
    if (count.hasOwnProperty(i)) {
      if (count[i] === maxIndex) {
        modes.push(Number(i));
      }
    }

  return modes;
};

export const isValidURL = (url) => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i.test(url);

// Get Quesy Params from URL
export const getQueryParamsObj = (string) => {
  const queryParams = {};
  const params = new URLSearchParams(string);
  if (params) params.forEach((value, key) => (queryParams[key] = value));
  return queryParams;
};

//Custom Sort array of obj by key
export const compareValues = (key, order = "ascend") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
    const comparison = a[key].localeCompare(b[key]);
    return order === "descend" ? comparison * -1 : comparison;
  };
};

// Redirect user to Yii Login Page
export const redirectToYii = (path = "site/login", addReturnUrl = true) => {
  window.location.href = `${process.env.REACT_APP_YII_APP_URL}/${path}${addReturnUrl ? `?returnUrl=${window.location.href}` : ""}`;
};

// Get Blank Card/Category Item
export const getBlankCatCard = (i = 0) => ({ id: uniqueID(), name: "", desc: "", isNew: true, position: i });
