import moment from "moment";
import {
  RESULT_DIRECT_SUCCESS,
  RESULT_INDIRECT_SUCCESS,
  RESULT_DIRECT_FAILURE,
  RESULT_INDIRECT_FAILURE,
  RESULT_FAILED,
  RESULT_SUCCESS,
  TESTER_CATEGORY,
  CARD_TYPE_OPEN,
  QUEST_TYPE_TEXT,
  OPTION_TYPE_OTHERS,
  CARD_TYPE_HYBRID,
  RESULT_DIRECT_SKIP,
  RESULT_INDIRECT_SKIP,
  MERGED_CATEGORY,
  INCLUDE_PARTICIPANT,
} from "../actions/constants";
import { FORWARD, BACKWARD, SIBLING } from "./icons";
import { mean, mode } from "../utills/common";
import countries from "./countries";

export const getParticipantEmail = (participant) => (participant.firstName ? participant.firstName : "-");

// Returns duration in Seconds
export const calculateTimeTaken = (start, end, durationOnly = false) => {
  let duration = moment.duration(moment(end).diff(moment(start)));
  return durationOnly ? duration.asSeconds() : getDurationInMMSS(duration);
};

export const getDurationInMMSS = (duration) => {
  if (!isFinite(duration)) return "0 Sec";

  if (!isNaN(parseInt(duration))) duration = moment.duration(duration * 1000);

  let hhmmss = "";
  if (duration.get("hours") > 0) hhmmss += duration.get("hours") + " Hour ";
  if (duration.get("minutes") > 0) hhmmss += duration.get("minutes") + " Min ";
  if (duration.get("seconds") >= 0) hhmmss += duration.get("seconds") + " Sec";

  return hhmmss.trim();
};

export const dirctnessLabel = (type) => {
  const labels = {
    directSuccess: "Direct Success",
    indirectSuccess: "Indirect Success",
    directFail: "Direct Fail",
    indirectFail: "Indirect Fail",
    directSkip: "Direct Skip",
    indirectSkip: "Indirect Skip",
  };

  return labels[type] ? labels[type] : "";
};

export const getResultType = (result) => {
  const types = { 1: "directSuccess", 2: "indirectSuccess", 3: "directFail", 4: "indirectFail", 5: "directSkip", 6: "indirectSkip" };

  return types[result] ? types[result] : "directFail";
};

export const getLabelOptions = () => [
  {
    color: "#00DFD2",
    key: "directSuccess",
    text: "Direct Success",
    className: "text-success-3",
    value: RESULT_DIRECT_SUCCESS,
  },
  {
    color: "#AFF9E3",
    key: "indirectSuccess",
    text: "Indirect Success",
    className: "text-success-4",
    value: RESULT_INDIRECT_SUCCESS,
  },
  {
    color: "#FF83A1",
    key: "directFail",
    text: "Direct Failure",
    className: "text-red-6",
    value: RESULT_DIRECT_FAILURE,
  },
  {
    isLast: true,
    color: "#FFC8D1",
    key: "indirectFail",
    text: "Indirect Failure",
    className: "text-red-7",
    value: RESULT_INDIRECT_FAILURE,
  },
  { key: "directSkip", text: "Direct Skip", value: RESULT_DIRECT_SKIP, className: "text-blue-16", color: "#7CB1FF" },
  {
    isLast: true,
    color: "#B1CFFB",
    key: "indirectSkip",
    text: "Indirect Skip",
    className: "text-blue-6",
    value: RESULT_INDIRECT_SKIP,
  },
];

export const getResultText = (result) => {
  const item = getLabelOptions().find((label) => label.value === result);
  return item ? item.text : "NA";
};

export const getPathLegends = () => [
  { key: "forward", text: "Forward", icon: FORWARD },
  { key: "backward", text: "Backward", icon: BACKWARD },
  { key: "sibling", text: "Sibling", icon: SIBLING, isLast: true },
];

// Get Result ClassName
export const taskResultClassName = (status) => {
  switch (status) {
    case RESULT_DIRECT_SUCCESS:
      return "text-success-3";
    case RESULT_INDIRECT_SUCCESS:
      return "text-success-4";
    case RESULT_DIRECT_FAILURE:
      return "text-red-6";
    case RESULT_INDIRECT_FAILURE:
      return "text-red-7";
    case 5:
      return "text-blue-16";
    case 6:
      return "text-blue-6";
    default:
      return "text-red-6";
  }
};

// Get Country
export const getCountryName = (countryCode) => {
  const country = countries.find((item) => item.value === countryCode);
  return country ? country.text : "";
};

// Get Avg From Array
export const getAvgFromArray = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const getTaskSuccessfullRate = (item, totalTasks) => {
  const successTasks = item.taskAnswers.filter(
    (taskAns) => taskAns.result === RESULT_DIRECT_SUCCESS || taskAns.result === RESULT_INDIRECT_SUCCESS
  );

  return `${successTasks.length} of ${totalTasks} Tasks`;
};

export const testCompeletion = (item, totalTasks) => parseInt((item.taskAnswers.length / totalTasks) * 100) + "%";

export const getDeviceDetails = (os, browser, onlyBrowser = false) => {
  const browserName = browser.name + " " + (browser.version ? parseInt(browser.version) : "");
  if (onlyBrowser) return browserName;

  let device = os.name;
  if (device.toLowerCase() === "windows" && os.versionName) device += " " + os.versionName;
  else if (os.version) device += " " + os.version;
  return device + ", " + browserName;
};

export const getPlatformType = (type) => {
  if (!type) return "";
  type = type.toLowerCase();
  if (type !== "desktop") return type + " alternate";
  return type;
};

export const calculateResultMatrix = (response, test, considerUnsortedCards = false) => {
  let { cards, categories } = test.card_sort;
  if (test.settings.card_sort.type === CARD_TYPE_OPEN) {
    // in case of open card sort, only consider tester categories
    categories = categories.filter((cat) => cat.type === TESTER_CATEGORY || cat.type === MERGED_CATEGORY);
  }

  //To remove category without names(mainly abandoned response)
  if (test.settings.card_sort.type === CARD_TYPE_OPEN || test.settings.card_sort.type === CARD_TYPE_HYBRID)
    categories = categories.filter((cat) => cat.hasOwnProperty("name"));

  const responses = response.filter((resp) => resp.status !== RESULT_FAILED);
  const result = cards.map((card) => {
    const res = responses.reduce((res, resp) => {
      if (!resp || !resp.cardSort) return res;

      const { categories } = resp.cardSort;
      for (let i in categories) {
        const isExists = categories[i].reduce((exists, item) => {
          if (exists) return exists;
          return item.id === card._id;
        }, false);

        if (isExists) {
          if (!res[i]) res[i] = 0;
          res[i] += 1;
        }
      }
      return res;
    }, {});

    const catData = [];
    for (let i in res) {
      const category = categories.find((cat) => cat._id === i);
      catData.push({ ...category, frequency: res[i] });
    }

    return { ...card, categories: catData };
  });
  const minFrequencyRequiredToColor = 2; // no need to make frequency less than this constant to highest, and no need to color it
  result.forEach((res) => {
    let first = 0,
      second = 0,
      third = 0;
    res.categories.forEach((cat) => {
      if (cat.frequency > first) {
        third = second;
        second = first;
        first = cat.frequency;
      } else if (cat.frequency > second) {
        third = second;
        second = cat.frequency;
      } else if (cat.frequency > third) {
        third = cat.frequency;
      }

      // calculate popular placement matrix
      cat.popularPlacementMatrix = responses.length ? ((cat.frequency * 100) / responses.length).toFixed(1) : 0;
    });

    // assign highest value 1,2,3 such that for each similar frequencies the highest should remain same always
    let ishighest1Set = false,
      ishighest2Set = false,
      isHeightest3Set = false;
    res.categories.map((cat) => {
      if (cat.frequency > minFrequencyRequiredToColor) {
        if (!ishighest1Set && cat.frequency === first) {
          cat.highest = 1;
          ishighest1Set = true;
        } else if (!ishighest2Set && cat.frequency === second) {
          if (cat.frequency === first) {
            cat.highest = 1;
          } else {
            cat.highest = 2;
          }
          ishighest2Set = true;
        } else if (!isHeightest3Set && cat.frequency === third) {
          if (cat.frequency === first) {
            cat.highest = 1;
          } else if (cat.frequency === second) {
            cat.highest = 2;
          } else {
            cat.highest = 3;
          }
          isHeightest3Set = true;
        }
      }
      return cat;
    });

    if (considerUnsortedCards) {
      // how many times the card is left unsorted
      let sortedCount = 0;
      res.categories.forEach((cat) => (sortedCount += cat.frequency));
      const unsortedCount = responses.length - sortedCount;

      if (unsortedCount) {
        res.categories.push({
          _id: "unsorted",
          name: "Unsorted",
          frequency: unsortedCount,
          popularPlacementMatrix: responses.length ? ((unsortedCount * 100) / responses.length).toFixed(1) : 0,
        });
      }
    }
  });

  if (considerUnsortedCards) {
    // unsorted category
    if (!categories.find((cat) => cat._id === "unsorted")) {
      categories.push({
        _id: "unsorted",
        name: "Unsorted",
      });
    }
  }

  return { cards, categories, result };
};

export const calculateConfidenceInSorting = (response) => {
  const CONFIDENCE_LOW = 1;
  const CONFIDENCE_MEDIUM = 2;
  const CONFIDENCE_HIGH = 3;

  const cardsTravelHistory = {};
  response.forEach((res) => {
    if (cardsTravelHistory[res._id] === undefined || !cardsTravelHistory[res._id]) cardsTravelHistory[res._id] = { mean: 0, mode: [] };

    if (res.cardSort && res.cardSort.cards) {
      let pathLengthArray = [];
      Object.keys(res.cardSort.cards).forEach((cardId) => {
        pathLengthArray.push(res.cardSort.cards[cardId].length);
        if (cardsTravelHistory[res._id][cardId] === undefined || !cardsTravelHistory[res._id][cardId])
          cardsTravelHistory[res._id][cardId] = { pathLength: 0, confidence: "" };
        cardsTravelHistory[res._id][cardId].pathLength = res.cardSort.cards[cardId].length;
      });

      const MEAN = mean(pathLengthArray);
      const MODE = mode(pathLengthArray);

      cardsTravelHistory[res._id].mean = MEAN;
      cardsTravelHistory[res._id].mode = MODE;

      Object.keys(res.cardSort.cards).forEach((cardId) => {
        const pathLength = cardsTravelHistory[res._id][cardId].pathLength;
        let confidence = "";

        if (MODE.length === 1) {
          // Only one mode is available for the series
          if (pathLength === MODE[0]) {
            if (pathLength >= MEAN) confidence = CONFIDENCE_MEDIUM;
            else confidence = CONFIDENCE_HIGH;
          } else if (pathLength < MODE[0]) {
            confidence = CONFIDENCE_HIGH;
          } else {
            confidence = CONFIDENCE_LOW;
          }
        } else {
          // Mode is ambiguous
          if (pathLength === MEAN) {
            confidence = CONFIDENCE_MEDIUM;
          } else if (pathLength < MEAN) {
            confidence = CONFIDENCE_HIGH;
          } else {
            confidence = CONFIDENCE_LOW;
          }
        }

        cardsTravelHistory[res._id][cardId].confidence = confidence;
      });
    }
  });

  const cardConfidenceMatrix = {};
  Object.keys(cardsTravelHistory).forEach((userId) => {
    Object.keys(cardsTravelHistory[userId]).forEach((cardId) => {
      if (cardId !== "mean" && cardId !== "mode") {
        if (cardConfidenceMatrix[cardId] === undefined || !cardConfidenceMatrix[cardId])
          cardConfidenceMatrix[cardId] = { individualUsersConfidence: [], confidence: "" };
        cardConfidenceMatrix[cardId].individualUsersConfidence.push(cardsTravelHistory[userId][cardId].confidence);
      }
    });
  });

  Object.keys(cardConfidenceMatrix).forEach((cardId) => {
    const overallConfidence = mode(cardConfidenceMatrix[cardId].individualUsersConfidence)[0];
    let conf = "";
    switch (overallConfidence) {
      case CONFIDENCE_LOW:
        conf = "Low";
        break;
      case CONFIDENCE_MEDIUM:
        conf = "Medium";
        break;
      case CONFIDENCE_HIGH:
        conf = "High";
        break;
      default:
        conf = "";
        break;
    }
    cardConfidenceMatrix[cardId].confidence = conf;
  });

  return cardConfidenceMatrix;
};

export const getSurveyResultData = (response, test, type) => {
  const surveyQuestions = test && test.survey ? test.survey[type] : [];
  return surveyQuestions.map((question) => {
    const { question_type } = question;
    const questionResult = response.reduce((resp, { testSurveyAnswers, participant, endTime, included }) => {
      if (testSurveyAnswers && testSurveyAnswers[type]) {
        const answer = testSurveyAnswers[type].find((res) => res.id === question._id);
        if (answer) return [...resp, { ...answer, participant, endTime, included }];
      }
      return resp;
    }, []);
    const option =
      question_type === QUEST_TYPE_TEXT
        ? question.option
        : question.option.map((opt) => ({
            ...opt,
            result: questionResult.reduce(
              (response, resp) => {
                const { answer, text } = resp;
                let { count, others } = response;
                if (answer.includes(opt._id)) count++;
                if (opt.type === OPTION_TYPE_OTHERS && text.trim()) others.push(text);
                return { ...response, count, others };
              },
              { count: 0, others: [] }
            ),
          }));

    const barData = question_type === QUEST_TYPE_TEXT ? [] : option.map((opt) => ({ name: opt.label, Total: opt.result.count }));
    return { ...question, option, questionResult, barData };
  });
};

export const createDesiredResponse = (response) => {
  response = response.map((res, index) => {
    const participant = { ...res.participant, name: `P${res.participant.respIndex ? res.participant.respIndex : index + 1}` };
    const { others } = participant;

    if (others && others.age) {
      let ageGroup = "";
      const { age } = others;
      if (age < 21) ageGroup = "Below 21 years";
      else if (age >= 21 && age <= 30) ageGroup = "21-30 years";
      else if (age >= 31 && age <= 40) ageGroup = "31-40 years";
      else if (age >= 41 && age <= 54) ageGroup = "41-54 years";
      else if (age > 54) ageGroup = "Above 54 years";

      if (ageGroup) participant.others = { ...others, ageGroup };
    }
    return { ...res, participant };
  });

  const allResponses = response.reverse();
  const includedResponses = allResponses.filter((resp) => resp.included === INCLUDE_PARTICIPANT.YES);
  return {
    allResponses,
    includedResponses,
    response: allResponses,
    reportResponses: includedResponses.filter((resp) => resp.status === RESULT_SUCCESS),
  };
};

export const getMemberId = (queryParams) =>
  queryParams && (queryParams.memberId || queryParams.memberID || queryParams.memberid || queryParams.member_id);

export const getEndTimeText = (endTime, returnTimestamp = false) => {
  if (endTime) return returnTimestamp ? moment(endTime).unix() : moment(endTime).format("Do MMM, YYYY hh:mm A");
  return returnTimestamp ? 0 : "--";
};

export const checkParticipantUpdateData = (resp) => {
  const initial = {
    isAge: false,
    isGender: false,
    isEthnicity: false,
    isMemberID: false,
    isDataUpdated: false,
    isNameUpdated: false,
    isNationality: false,
  };

  return resp.reduce((result, item) => {
    const { queryParams, isDataUpdated, dataUpdated, gender, nationality, others } = item.participant;

    // Is Member ID Exists
    if (!result.isMemberID) {
      const memberID = getMemberId(queryParams);
      result.isMemberID = (queryParams && Object.keys(queryParams).length !== 0) || memberID ? true : false;
    }

    if (!result.isGender) result.isGender = gender !== undefined;
    if (!result.isAge && others) result.isAge = others.age !== undefined;
    if (!result.isDataUpdated && isDataUpdated) result.isDataUpdated = true;
    if (!result.isNationality) result.isNationality = nationality !== undefined;
    if (!result.isEthnicity && others) result.isEthnicity = others.ethnicity !== undefined;
    if (!result.isNameUpdated) result.isNameUpdated = isDataUpdated !== undefined && dataUpdated.includes("firstName");

    return result;
  }, initial);
};

// Get Most Wrong/First Click Node
export const getMostFCorWGNode = (treeNodeData, type, taskId) => {
  return treeNodeData.reduce((findNode, item) => {
    if (item.tasks) {
      const node = item.tasks.find((task) => task.id === taskId);

      // Add Node if not exists
      if (node[type] && !findNode) return item;

      // Get worngCount
      if (findNode && findNode.tasks) {
        const previousNode = findNode.tasks.find((task) => task.id === taskId);

        // Compare node with previous added node
        return node[type] && node[type] > previousNode[type] ? item : findNode;
      }
    }

    return findNode;
  }, null);
};

export const getPercentValue = (value, total, valueOnly = false) => {
  const division = value / total;
  const percentValue = isNaN(division) ? 0 : (division * 100).toFixed(2);

  return valueOnly ? percentValue : `${percentValue}%`;
};
