import axios from "../utills/axios";
import { TEST } from "./types";
import { TEST_TYPE_PD } from "./constants";

// Save Task
export const saveTask = (task, test) => async (dispatch) => {
  if (task && test) {
    dispatch({ type: TEST.SAVING, payload: true });
    dispatch({ type: TEST.TASK_SAVING, payload: true });

    try {
      const tasks = test?.tasks?.map((task) => ({ ...task, isNew: false })) || [];

      // Update Task
      if (task._id) {
        const result = await axios.put(`/api/task/${task._id}`, task);
        const taskIndex = tasks.findIndex((tsk) => tsk._id === task._id);
        if (test.type === TEST_TYPE_PD) result.data.image += `?v=${new Date().getTime()}`;
        tasks.splice(taskIndex, 1, result.data);
      } else {
        const result = await axios.post(`/api/test/${test._id}/task`, task);
        tasks.push({ ...result.data, isNew: true });
      }

      dispatch({ type: TEST.TASK_SAVING, payload: false });
      dispatch({ type: TEST.UPDATED, payload: { ...test, tasks } });
    } catch (error) {
      console.log(error);
      dispatch({ type: TEST.TASK_SAVING, payload: false });
    }
  }
};

// Delete Task
export const deleteTask = (taskId, test) => async (dispatch) => {
  if (taskId && test) {
    dispatch({ type: TEST.SAVING, payload: true });
    dispatch({ type: TEST.TASK_SAVING, payload: true });

    try {
      await axios.delete(`/api/test/${test._id}/task/${taskId}`);

      dispatch({ type: TEST.TASK_SAVING, payload: false });
      dispatch({ type: TEST.UPDATED, payload: { ...test, tasks: test?.tasks.filter((task) => task._id !== taskId) } });
    } catch (error) {
      console.log(error);
      dispatch({ type: TEST.TASK_SAVING, payload: false });
    }
  }
};

// Add/Edit Marker
export const saveMarker = (task, marker) => async (dispatch) => {
  if (task && marker) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const markers = task.markers;
      if (marker._id) {
        const result = await axios.put(`/api/markers/${marker._id}`, marker);
        const markIndex = markers.findIndex((mark) => mark._id === marker._id);
        markers.splice(markIndex, 1, result.data);
      } else {
        const result = await axios.post(`/api/task/${task._id}/marker`, marker);
        markers.push(result.data);
      }

      dispatch({ type: TEST.SAVING, payload: false });
    } catch (error) {
      console.log(error);
    }
  }
};

// Delete Marker
export const deleteMarker = (task, markerId) => async (dispatch) => {
  if (task && markerId) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      await axios.delete(`/api/task/${task._id}/marker/${markerId}`);

      // Remove Deleted Marker
      task.markers = task.markers.filter((marker) => marker._id !== markerId);

      dispatch({ type: TEST.SAVING, payload: false });
    } catch (error) {
      console.log(error);
    }
  }
};
