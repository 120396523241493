import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import TopNavbar from "../layouts/TopNavbar";
import ReportProvider from "./ReportProvider";

const { Content } = Layout;

export default function ReportLayout() {
  return (
    <Layout className='h-100vh test-report'>
      <Content className='bg-white h-100'>
        <TopNavbar />

        <ReportProvider>
          <Outlet />
        </ReportProvider>
      </Content>
    </Layout>
  );
}
