import { NOTIFICATION } from "../actions/types";

const initialState = { message: "", description: "", type: "", duration: 3.5 };

const notifyReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case NOTIFICATION.REMOVE:
      return initialState;
    case NOTIFICATION.SET:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default notifyReducer;
