import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { useMemo, useContext, createContext } from "react";
import {
  verifyTest,
  resetStudy,
  updateState,
  markComplete,
  saveParticipant,
  markScreenFailed,
  saveTaskResponse,
} from "../../actions/study";
import "../../assets/css/study.css";

// StudyContext to keep the latest state
const StudyContext = createContext(null);

// useStudyContext to share this components state everywhere this component is imported
export const useStudyContext = () => {
  const context = useContext(StudyContext);

  if (!context) throw new Error("useStudyContext context must be use inside ReportProvider");

  return context;
};

const StudyProvider = ({
  study,
  children,
  verifyTest,
  resetStudy,
  updateState,
  markComplete,
  saveParticipant,
  markScreenFailed,
  saveTaskResponse,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { test, branding, isPreview = false } = study;

  const logo = branding?.logo || ""; // Default Logo
  const color = branding?.color || "#2cbfca"; // Default Color
  const { testerLanguage = language, enableParticipantForm = false } = test || {};

  const memoizedValue = useMemo(
    () => ({
      t,
      test,
      study,
      logo,
      color,
      isPreview,
      verifyTest,
      testerLanguage,
      enableParticipantForm,
      saveParticipant,
      resetStudy,
      updateState,
      markComplete,
      markScreenFailed,
      saveTaskResponse,
    }),
    [
      t,
      test,
      logo,
      study,
      color,
      isPreview,
      verifyTest,
      testerLanguage,
      enableParticipantForm,
      saveParticipant,
      resetStudy,
      updateState,
      markComplete,
      markScreenFailed,
      saveTaskResponse,
    ]
  );

  return (
    <StudyContext.Provider value={memoizedValue}>
      <ConfigProvider theme={{ token: { colorSuccess: color, colorPrimary: color } }}>{children}</ConfigProvider>
    </StudyContext.Provider>
  );
};

StudyProvider.propTypes = {
  study: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  verifyTest: PropTypes.func.isRequired,
  resetStudy: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  markComplete: PropTypes.func.isRequired,
  saveParticipant: PropTypes.func.isRequired,
  markScreenFailed: PropTypes.func.isRequired,
  saveTaskResponse: PropTypes.func.isRequired,
};

const mapStateToProps = ({ study }) => ({ study });

export default connect(mapStateToProps, {
  verifyTest,
  resetStudy,
  updateState,
  markComplete,
  saveParticipant,
  markScreenFailed,
  saveTaskResponse,
})(StudyProvider);
