import auth from "./auth";
import test from "./test";
import study from "./study";
import survey from "./survey";
import report from "./report";
import message from "./message";
import checkout from "./checkout";
import notification from "./notification";
import { combineReducers } from "redux";
import { LOGOUT } from "../actions/types";

const appReducer = combineReducers({ auth, test, survey, report, study, message, checkout, notification });

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    if (localStorage?.state) {
      const oldState = JSON.parse(localStorage.state);
      state = oldState && oldState?.study ? { study: oldState.study } : undefined;
    } else {
      state = undefined;
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
