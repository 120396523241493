import App from "./App";
import React from "react";
import { createRoot } from "react-dom/client";

import "semantic-ui-css/semantic.min.css";

import "./assets/css/App.css";
import "./assets/css/antd-styles.css";

// Internationalization
import "./utills/i18n";

createRoot(document.getElementById("root")).render(<App />);
