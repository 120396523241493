import "moment-timezone";
import axios from "../utills/axios";
import { setAlert } from "./alert";
import { redirectToYii } from "../utills/common";
import { USER_LOADED, AUTH_ERROR, USER_STATE_UPDATE } from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  dispatch({ type: USER_STATE_UPDATE, payload: { loading: true, error: null } });

  try {
    const res = await axios.get("/api/auth");
    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (error) {
    const { response } = error;
    dispatch({ type: AUTH_ERROR, payload: { error: (response && response?.data) || error?.message } });
  }
};

// User logout
export const logout = () => async (dispatch) => {
  try {
    await axios.get("/api/auth/logout");
    redirectToYii("site/logout?dashboardtype=cardsorting");
    // dispatch({ type: LOGOUT });
  } catch (err) {
    dispatch(setAlert("Unable to logout. Please try again.", "error"));
  }
};

// Update User State
export const updateUserState = (payload) => async (dispatch) => dispatch({ type: USER_LOADED, payload });

const axiosRequest = async (url, method, data) => {
  return new Promise(async (resolve, reject) => {
    axios({ method, url, data })
      .then((res) => resolve(res.data))
      .catch(reject);
  });
};

// Create an Account
export const createAccount = () => axiosRequest("/api/account/create-account", "get");

// Update Account Info
export const updateAccountInfo = (account, data) => axiosRequest(`/api/account/${account?._id}`, "put", data);

// Add/Update Branding Image
export const addUpdateBrandingImage = (account, data) => axiosRequest(`/api/account/${account?._id}/image`, "post", { data });

// Delete Branding Image
export const deleteBrandingImage = (account, imageId) => axiosRequest(`/api/account/${account?._id}/image/${imageId}`, "delete");
