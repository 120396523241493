import axios from "../utills/axios";
import { TEST } from "./types";
import { saveTask } from "./task";
import { getDefaultTree, filterAnswers } from "../utills/treeFunctions";

// Update Tree
export const updateTestTree =
  (test, saveStateOnly = false) =>
  async (dispatch) => {
    if (saveStateOnly) {
      dispatch({ type: TEST.UPDATE_TREE, payload: test.tree });
      return;
    }

    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const tree = test.tree;

      let result;
      if (tree._id) {
        result = await axios.put(`/api/tree/${tree._id}`, tree);
      } else {
        result = await axios.post(`/api/test/${test._id}/tree`, tree);
      }

      test.tree = result.data;

      // Update Task Answers if required
      if (test.tasks && test.tasks.length) {
        const treeData = test.tree.treeData;
        test.tasks.map((task) => {
          // Update Task Answer
          if (task.answers && task.answers.length) {
            const filteredAnswers = filterAnswers(treeData, task.answers);

            // Check if answer updated
            if (task.answers.sort().toString() !== filteredAnswers.sort().toString()) {
              task.answers = filteredAnswers;
              dispatch(saveTask(task, test));
            }
          }

          return task;
        });
      }

      dispatch({ type: TEST.UPDATED, payload: test });
      dispatch({ type: TEST.UPDATE_TREE, payload: test.tree }); // Update Tree State
    } catch (error) {
      console.log(error);
    }
  };

// Delete Test Tree
export const deleteTestTree = (test) => async (dispatch) => {
  dispatch({ type: TEST.SAVING, payload: true });

  try {
    const tree = test.tree;

    const res = await axios.delete(`/api/test/${test._id}/tree/${tree._id}`);
    test.tasks = res.data.tasks; // Update the task becoz of tree deletion.
    test.tree = getDefaultTree();
    dispatch({ type: TEST.UPDATED, payload: test });
    dispatch({ type: TEST.UPDATE_TREE, payload: test.tree }); // Update Tree State
  } catch (error) {
    console.log(error);
  }
};
