import axios from "../utills/axios";
import { REPORT_LOAD, REPORT_RESET, REPORT_FILTER, REPORT_FILTER_SET, AUTH_ERROR } from "./types";
import {
  RESULT_FAILED,
  RESULT_SUCCESS,
  TEST_TYPE_TT,
  RESULT_DIRECT_SUCCESS,
  RESULT_INDIRECT_SUCCESS,
  DIRECTION_FORWARD,
  DIRECTION_BACKWARD,
  UNCLEAR_CATEGORY,
  RESULT_SCREENOUT,
  QUEST_TYPE_TEXT,
  OPTION_TYPE_OTHERS,
  SURVEY_TYPE_SCREENER,
  GENDER_MALE,
  INCLUDE_PARTICIPANT,
  RESULT_DIRECT_FAILURE,
  RESULT_INDIRECT_FAILURE,
  RESULT_DIRECT_SKIP,
  RESULT_INDIRECT_SKIP,
} from "./constants";
import { setAlert } from "./alert";
import { getTestStatuslabelText, getTestTypeText, surveyTypes, isSurveyEnabled, getOptionType } from "../utills/testFunctions";
import { getNodePath } from "../utills/treeFunctions";
import {
  getDurationInMMSS,
  calculateTimeTaken,
  getAvgFromArray,
  // getTaskSuccessfullRate,
  getDeviceDetails,
  getResultText,
  getCountryName,
  calculateConfidenceInSorting,
  calculateResultMatrix,
  getSurveyResultData,
  getMemberId,
  getEndTimeText,
  checkParticipantUpdateData,
  createDesiredResponse,
  getMostFCorWGNode,
  getPercentValue,
} from "../utills/reportFunctions";
import { getGender } from "../utills/common";
import moment from "moment";
import Bowser from "bowser";
import * as XLSX from "xlsx";

// Get Test
export const getTestDetails = (testId) => async (dispatch) => {
  // Reset State
  dispatch({ type: REPORT_RESET });

  try {
    const res = await axios.get(`/api/response/test/${testId}`);
    let { test, response } = res.data;
    dispatch({ type: REPORT_LOAD, payload: { test, ...createDesiredResponse(response) } });
  } catch (error) {
    console.log(error, error.response);
    if (error && error.response && error.response.data && error.response.status) {
      if (error.response.status === 401) dispatch({ type: AUTH_ERROR });
      else dispatch({ type: REPORT_LOAD, payload: { error: error.response.data.msg } });
    }
  }
};

// Filter Report
export const filterReport = (filters) => async (dispatch) => dispatch({ type: REPORT_FILTER, payload: filters });

// Set Filters
export const setFilters = (filters) => async (dispatch) => dispatch({ type: REPORT_FILTER_SET, payload: filters });

const memberID = (queryParams) => {
  let memberID = getMemberId(queryParams);
  if (!memberID) {
    if (queryParams && Object.keys(queryParams).length > 0) {
      const firstKey = Object.keys(queryParams)[0];
      memberID = queryParams[firstKey];
    } else memberID = "--";
  }
  return memberID;
};

const memberAnswer = (answers, options) => {
  return answers
    .reduce((result, optionId) => {
      const option = options.find((opt) => opt._id === optionId);
      return option
        ? [...result, option.label + (option.type === OPTION_TYPE_OTHERS ? ": " + option.result.others.join(",") : "")]
        : result;
    }, [])
    .join(", ");
};

const participantHeader = (update) => {
  const header = ["Participant"];
  if (update.isAge) header.push("Age");
  if (update.isGender) header.push("Gender");
  if (update.isEthnicity) header.push("Ethnicity");
  if (update.isNationality) header.push("Nationality");
  if (update.isMemberID) header.push("Member ID");
  return header;
};

const participantBody = (participant, update, included = INCLUDE_PARTICIPANT.YES) => {
  const { queryParams, gender, others, nationality, name } = participant;
  const body = [name + (included === INCLUDE_PARTICIPANT.NO ? " (excluded)" : "")];

  if (update.isAge) body.push(others && others.age ? others.age : "--");
  if (update.isGender) body.push(gender !== undefined ? (gender === GENDER_MALE ? "Male" : "Female") : "--");
  if (update.isEthnicity) body.push(others && others.ethnicity ? others.ethnicity : "--");
  if (update.isNationality) body.push(nationality ? getCountryName(nationality) : "--");
  if (update.isMemberID) body.push(memberID(queryParams));
  return body;
};

const getQuestionnaireExcelData = (responses, test, type, update) => {
  const totalRespondents = responses.reduce(
    (total, resp) =>
      resp.testSurveyAnswers && resp.testSurveyAnswers[type] && resp.testSurveyAnswers[type].length !== 0 ? total + 1 : total,
    0
  );
  let initArray = [];
  if (type === SURVEY_TYPE_SCREENER) {
    const screenedRespondents = responses.reduce((total, res) => (res.status === RESULT_SUCCESS ? total + 1 : total), 0);
    initArray = [[`${screenedRespondents} Pass of ${responses.length} Participants Screened`], []];
  }

  const resultData = getSurveyResultData(responses, test, type);
  if (resultData && resultData.length) {
    initArray = [...initArray, ["Total Questions: " + resultData.length], []];

    const heading = ["S.No.", ...participantHeader(update), "Completed On", "Answer"];

    return resultData.reduce((result, question, index) => {
      let questionData = [[`Question ${index + 1}: ${question.label}`], ["Answers"]];
      if (index) questionData.unshift([]);

      if (question.question_type === QUEST_TYPE_TEXT) {
        const answers = question.questionResult.map((res, index) => [
          index + 1,
          ...participantBody(res.participant, update, res.included),
          getEndTimeText(res.endTime),
          res.text,
        ]);
        questionData = [...questionData, heading, ...answers];
      } else {
        const heading2 = ["S.No.", "Answer Choices", "Percentage", "No of Responses"];
        const answers = question.option.map((opt, index) => [
          index + 1,
          opt.label + (opt.type === OPTION_TYPE_OTHERS ? ": " + opt.result.others.join(",") : ""),
          getPercentValue(opt.result.count, totalRespondents),
          opt.result.count,
        ]);

        const userTablebody = question.questionResult.map((res, index) => [
          index + 1,
          ...participantBody(res.participant, update, res.included),
          getEndTimeText(res.endTime),
          memberAnswer(res.answer, question.option),
        ]);

        questionData = [...questionData, heading2, ...answers, [], ["Answer by Testers"], heading, ...userTablebody];
      }

      return [...result, ...questionData];
    }, initArray);
  }
  return null;
};

export const getTreeArray = (item, treeInfo = []) => {
  const len = treeInfo.length > 0 ? treeInfo[treeInfo.length - 1].length : 0;

  item.forEach((item2) => {
    treeInfo.push([...Array(len).fill(null), item2.name]);

    if (item2.children && item2.children.length > 0) getTreeArray(item2.children, treeInfo);
  });

  return treeInfo;
};

// Export Report
export const exportReport = (responses, test, reportResponses, includedResponses) => async (dispatch) => {
  const wb = XLSX.utils.book_new();

  const { tasks, tree, settings, survey } = test;
  const { treeData } = tree;

  const update = checkParticipantUpdateData(responses);
  const pHeader = [
    "S. No.",
    ...participantHeader(update),
    "Completed On",
    "Time Taken",
    "Time Taken (seconds)",
    "Test Completion",
    // "Task Success",
    "Device",
    "Task Completion rate \n(Completed / Total Tasks)",
    "Task success rate \n(Tasks successful / attempted)",
    "Total Mandatory Tasks",
    "Mandatory Tasks Completed",
    "Total Optional Tasks",
    "Optional Tasks Completed",
  ];
  const participants = [pHeader];
  const totalTasks = tasks.length;
  const timeTakenValues = [];
  let completed = 0,
    abandoned = 0,
    screenOut = 0,
    excluded = 0,
    totalResults = 0,
    successRate = 0,
    directness = 0,
    indirectSuccessRate = 0,
    partiPerformanceInfo = {};

  const initResultStatus = {
    [RESULT_DIRECT_SKIP]: 0,
    [RESULT_INDIRECT_SKIP]: 0,
    [RESULT_DIRECT_FAILURE]: 0,
    [RESULT_INDIRECT_FAILURE]: 0,
    [RESULT_DIRECT_SUCCESS]: 0,
    [RESULT_INDIRECT_SUCCESS]: 0,
  };

  const { mandateTaskIds, optionalTaskIds } = tasks.reduce(
    ({ mandateTaskIds, optionalTaskIds }, { _id, skippable }) => {
      if (skippable) return { mandateTaskIds, optionalTaskIds: [...optionalTaskIds, _id] };
      return { optionalTaskIds, mandateTaskIds: [...mandateTaskIds, _id] };
    },
    { mandateTaskIds: [], optionalTaskIds: [] }
  );

  responses.forEach((response, index) => {
    const { status, startTime, endTime, participant, taskAnswers, included } = response;
    if (status === RESULT_FAILED) abandoned++;
    if (status === RESULT_SUCCESS) completed++;
    if (status === RESULT_SCREENOUT) screenOut++;
    if (included === INCLUDE_PARTICIPANT.NO) excluded++;

    if (status === RESULT_SUCCESS && included === INCLUDE_PARTICIPANT.YES) {
      timeTakenValues.push(calculateTimeTaken(startTime, endTime, true)); // true: get duration in seconds

      // Calculate Success Rate & Directness
      if (test.type === TEST_TYPE_TT) {
        totalResults += (taskAnswers || []).length; // Total Results

        // For Participant Performance Sheet
        partiPerformanceInfo[participant.name] = taskAnswers.reduce((res, { result }) => ({ ...res, [result]: res[result] + 1 }), {
          ...initResultStatus,
        });

        for (let i = 0; i < taskAnswers.length; i++) {
          const result = taskAnswers[i].result;

          // SuccessRate/DirectNess
          if (result === RESULT_DIRECT_SUCCESS || result === RESULT_INDIRECT_SUCCESS) successRate++;
          if (result === RESULT_DIRECT_SUCCESS) directness++;
          if (result === RESULT_INDIRECT_SUCCESS) indirectSuccessRate++;
        }
      }
    }

    // Overview Data
    let deviceDetails = "NA";
    if (participant.userAgent) {
      const { os, browser } = Bowser.parse(participant.userAgent);
      deviceDetails = getDeviceDetails(os, browser);
    }

    const successTasks = taskAnswers.filter(
      (taskAns) => taskAns.result === RESULT_DIRECT_SUCCESS || taskAns.result === RESULT_INDIRECT_SUCCESS
    );

    const totalMandateCompleted = taskAnswers.reduce((total, { id }) => (mandateTaskIds.includes(id) ? total + 1 : total), 0);
    const totalOptinalCompleted = taskAnswers.reduce((total, { id }) => (optionalTaskIds.includes(id) ? total + 1 : total), 0);

    const pData = [
      index + 1,
      ...participantBody(participant, update, included),
      getEndTimeText(endTime),
      calculateTimeTaken(startTime, endTime),
      Math.floor(calculateTimeTaken(startTime, endTime, true)),
      status === RESULT_SUCCESS ? "Completed" : status === RESULT_SCREENOUT ? "Screened Out" : "Abandoned",
      // getTaskSuccessfullRate(response, totalTasks),
      deviceDetails,
      getPercentValue(successTasks.length, totalTasks),
      getPercentValue(successTasks.length, taskAnswers.length),
      mandateTaskIds.length,
      totalMandateCompleted,
      optionalTaskIds.length,
      totalOptinalCompleted,
    ];

    participants.push(pData);
  });

  // Overview
  const overview = [
    ["Name: " + test.name],
    ["Total Tasks: " + totalTasks],
    ["Status: " + getTestStatuslabelText(test.status)],
    ["Type: " + getTestTypeText(test.type)],
    ["Launched on: " + moment(test.startTime).format("Do MMMM YYYY")],
    ["Expire on: " + moment(test.endTime).format("Do MMMM YYYY")],
    [null],
    ["Completion Rate"],
    [
      `Total Responses: ${responses.length}`,
      `Completed: ${completed - excluded}`,
      `Abandoned: ${abandoned}`,
      `Screened Out: ${screenOut}`,
      `Excluded: ${excluded}`,
    ],
    [null],
    ["Time Taken (in seconds)"],
    ["Min", "Max", "Avg"],
    [Math.floor(Math.min(...timeTakenValues)), Math.floor(Math.max(...timeTakenValues)), Math.floor(getAvgFromArray(timeTakenValues))],
    [null],
  ];

  // Convert successRate/Directness into percentage
  if (totalResults && test.type === TEST_TYPE_TT) {
    overview.push(
      ["Success Rate", getPercentValue(successRate, totalResults)],
      ["Directness Rate", getPercentValue(directness, totalResults)],
      ["Indirect Success Rate", getPercentValue(indirectSuccessRate, totalResults)]
    );
  }

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(overview), "Overview");
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(participants), "Participants");

  // Add Tasks Details
  const pathObj = reportResponses.reduce((obj, { participant }) => ({ ...obj, [participant.name]: [] }), {});
  const TPInfo = [
    [""],
    [""],
    [""],
    ["Expected / Correct Path"],
    ["Direct success"],
    ["Indirect success"],
    ["Direct failure"],
    ["Indirect failure"],
    ["Direct skip"],
    ["Indirect skip"],
    ["Avg Task Duration (seconds)"],
    ...Object.keys(pathObj).map((k) => [k]),
  ];

  // Participant Performance
  const durationHeader = [""];
  tasks.forEach((t) => durationHeader.push("Time Taken (seconds)", "Result"));
  const PPInfo = [[""], [""], [""], ["Avg Task Duration (seconds)"], ...Object.keys(pathObj).map((k) => [k])];
  Object.keys(partiPerformanceInfo).forEach((key) => {
    const item = partiPerformanceInfo[key];
    Object.keys(item).forEach((k) => (item[k] = getPercentValue(item[k], totalTasks)));
    partiPerformanceInfo[key] = Object.values(item);
  });

  const td = tasks.reduce(
    (td, task, i) => {
      const { description, skippable, answers } = task;
      const paths = answers.map((answer) => [getNodePath(treeData, answer, true)]).join("\n");

      const { timeTakenValues, resultStatus } = reportResponses.reduce(
        (resp, { taskAnswers, participant: { name } }) => {
          let path = "";
          let timeTaken = 0;
          let taskResult = RESULT_FAILED;

          if (taskAnswers.length !== 0) {
            const userResult = taskAnswers.find((item) => item.id === task._id);
            if (userResult) {
              const { startTime, endTime, result, answer, skipped } = userResult;

              if (result !== undefined) taskResult = result;

              if (startTime && endTime) {
                timeTaken = Math.floor((endTime - startTime) / 1000);

                resp.timeTakenValues.push(
                  calculateTimeTaken(new Date(startTime).toISOString(), new Date(endTime).toISOString(), true)
                );
              }

              if (skipped && answer.length <= 1) {
                path = "Skipped";
              } else {
                path = answer.reduce((result, ans) => {
                  const direction = ans.direction ? ans.direction : DIRECTION_FORWARD;

                  let directionText = "";
                  if (result) {
                    if (direction === DIRECTION_FORWARD) directionText = ">>";
                    else if (direction === DIRECTION_BACKWARD) directionText = "<<<<";
                    else directionText = "<<-->>";
                  }

                  return `${result} ${directionText} ${ans.label}`.trim();
                }, "");
              }
            }
          } else path = "--";

          resp.resultStatus[taskResult]++;

          const resText = getResultText(taskResult);
          const { taskPaths, participantPerformance } = td;

          td.taskPaths = { ...taskPaths, [name]: [...taskPaths[name], path, resText] };
          td.participantPerformance = { ...participantPerformance, [name]: [...participantPerformance[name], timeTaken, resText] };

          return resp;
        },
        {
          timeTakenValues: [],
          resultStatus: { ...initResultStatus },
        }
      );

      const totalKeys = reportResponses.length;
      const reqStatus = skippable ? "Optional " : "Mandatory";

      // For Task Paths
      td.taskPaths.title.push("Task " + (i + 1), null);
      td.taskPaths.desc.push(description, null);
      td.taskPaths.reqStatus.push(reqStatus, null);
      td.taskPaths.paths.push(paths, null);
      td.taskPaths.directSuccess.push(getPercentValue(resultStatus[RESULT_DIRECT_SUCCESS], totalKeys), null);
      td.taskPaths.indirectSuccess.push(getPercentValue(resultStatus[RESULT_INDIRECT_SUCCESS], totalKeys), null);
      td.taskPaths.directFail.push(getPercentValue(resultStatus[RESULT_DIRECT_FAILURE], totalKeys), null);
      td.taskPaths.indirectFail.push(getPercentValue(resultStatus[RESULT_INDIRECT_FAILURE], totalKeys), null);
      td.taskPaths.directSkip.push(getPercentValue(resultStatus[RESULT_DIRECT_SKIP], totalKeys), null);
      td.taskPaths.indirectSkip.push(getPercentValue(resultStatus[RESULT_INDIRECT_SKIP], totalKeys), null);
      td.taskPaths.avgD.push(Math.floor(getAvgFromArray(timeTakenValues)), null);

      // For Participant Performance
      td.participantPerformance.title.push("Task " + (i + 1), null);
      td.participantPerformance.desc.push(description, null);
      td.participantPerformance.reqStatus.push(reqStatus, null);
      td.participantPerformance.avgD.push(Math.floor(getAvgFromArray(timeTakenValues)), null);

      return td;
    },
    {
      taskPaths: {
        title: [],
        desc: [],
        reqStatus: [],
        paths: [],
        directSuccess: [],
        indirectSuccess: [],
        directFail: [],
        indirectFail: [],
        directSkip: [],
        indirectSkip: [],
        avgD: [],
        ...pathObj,
      },
      participantPerformance: {
        title: [],
        desc: [],
        reqStatus: [],
        avgD: [],
        ...pathObj,
      },
    }
  );

  Object.keys(td.taskPaths).forEach((key, i) => TPInfo[i].push(...td.taskPaths[key]));
  Object.keys(td.participantPerformance).forEach((key, i) => {
    if (partiPerformanceInfo[key]) {
      PPInfo[i].push(...td.participantPerformance[key], ...partiPerformanceInfo[key]);
    } else PPInfo[i].push(...td.participantPerformance[key]);
  });

  PPInfo.splice(4, 0, [
    ...durationHeader,
    "Direct success",
    "Indirect success",
    "Direct failure",
    "Indirect failure",
    "Direct skip",
    "Indirect skip",
  ]);

  // Screener headers
  for (let i = 0; i < 4; i++) PPInfo[i].push(...Array(6).fill(null));

  // Questionnaire
  if (isSurveyEnabled(test)) {
    surveyTypes().forEach(({ key, title }) => {
      if (settings && settings.survey[key].include) {
        const questions = survey[key];
        PPInfo[1].push(title, ...Array(questions.length - 1).fill(null));
        questions.forEach(({ _id, label, option, question_type }, i) => {
          PPInfo[2].push(`Question ${i + 1}: ${label}`);
          PPInfo[3].push(getOptionType(key, question_type).text);
          PPInfo[4].push(question_type === QUEST_TYPE_TEXT ? "--" : option.map(({ label }) => label).join(", "));

          const surveyD = reportResponses.reduce(
            (resp, response) => {
              const { testSurveyAnswers, participant } = response;
              const { text, answer } = testSurveyAnswers[key].find((ans) => ans.id === _id);
              const answers = answer
                .map((ansId) => {
                  const { type, label } = option.find(({ _id }) => _id === ansId);
                  return type === OPTION_TYPE_OTHERS ? text : label;
                })
                .join(", ");

              resp[participant.name] = question_type === QUEST_TYPE_TEXT ? text : answers;

              return resp;
            },
            { ...pathObj }
          );

          Object.keys(surveyD).forEach((key, i) => PPInfo[i + 5].push(surveyD[key]));
        });
      }
    });
  }

  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(TPInfo), "Task Paths");
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(PPInfo), "Participant Performance");

  // Get TreeNodes
  const getTreeNodes = (treeData, nodes = []) => {
    treeData.forEach((node) => {
      nodes.push(node);

      if (node.children && node.children.length > 0) getTreeNodes(node.children, nodes);
    });
    return nodes;
  };

  let treeInfo = getTreeArray(treeData);
  const treeNodeData = getTreeNodes(treeData);
  const largestArrLen = treeInfo.reduce((len, arr) => (arr.length > len ? arr.length : len), 0);
  const emptyArr = Array(largestArrLen).fill(null);
  const fcdHeading = [emptyArr, emptyArr, emptyArr];

  treeInfo = treeInfo.map((arr) => (arr.length !== largestArrLen ? [...arr, ...Array(largestArrLen - arr.length).fill(null)] : arr));

  tasks.forEach(({ _id, description }, ti) => {
    fcdHeading[0] = [...fcdHeading[0], `Task ${ti + 1}`, null, null];
    fcdHeading[1] = [...fcdHeading[1], description, null, null];
    fcdHeading[2] = [...fcdHeading[2], "First Click", "Destination", "CD / WD"];

    // Get Most Wrong Node & First Click Node
    const mwNode = getMostFCorWGNode(treeNodeData, "wcount", _id);

    treeNodeData.forEach(({ id, tasks }, i) => {
      if (tasks) {
        const { fcount = "", scount = "", wcount = "", answers = [] } = tasks.find((t) => t.id === _id);
        treeInfo[i].push(
          fcount ? fcount : "",
          scount || wcount ? scount || wcount : "",
          answers.includes(id) && (fcount || scount || wcount) ? "CD" : mwNode && mwNode.id === id ? "WD" : ""
        );
      } else treeInfo[i].push(...Array(3).fill(null));
    });
  });

  const FCDInfo = [
    [null, "Legend"],
    ["Correct Destination", "CD"],
    ["Most selected wrong destination", "WD"],
    ...fcdHeading,
    ...treeInfo,
  ];

  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(FCDInfo), "First Click and Destination");

  // Questionnaire
  if (isSurveyEnabled(test)) {
    surveyTypes().forEach((item) => {
      if (test && test.settings && test.settings.survey[item.key].include) {
        const excelData = getQuestionnaireExcelData(includedResponses, test, item.key, update);
        if (excelData) XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(excelData), item.title);
      }
    });
  }

  /* write workbook */
  XLSX.writeFile(wb, test.name + ".xlsx");
};

// Export Card Sorting Report
export const exportCardSortingReport = (responses, test) => async (dispatch) => {
  const update = checkParticipantUpdateData(responses);
  // Preparing Report Data
  const timeTakenValues = [];
  let completed = 0,
    abandoned = 0,
    screenOut = 0;

  let overviewData = [],
    participantsData = [],
    cardsData = [],
    categoriesData = [],
    resultMatrixData = [],
    categorisationConfidenceData = [];

  // Participants
  const totalCardsInTest = test.card_sort.cards.length;
  participantsData.push([
    "S. No.",
    "Participant",
    "Gender",
    "Nationality",
    "Time Taken",
    "Task Status",
    "Total Card Sorted",
    "Device",
  ]);
  responses.forEach((response, index) => {
    if (response.status === RESULT_FAILED) abandoned++;
    if (response.status === RESULT_SUCCESS) completed++;
    if (response.status === RESULT_SCREENOUT) screenOut++;

    if (response.status === RESULT_SUCCESS) {
      timeTakenValues.push(calculateTimeTaken(response.startTime, response.endTime, true)); // true: get duration in seconds
    }

    const { firstName, lastName, userAgent, nationality, gender, name } = response.participant;

    const participantSNo = index + 1;
    const participantName = firstName ? firstName + " " + lastName : name;
    const participantGender = getGender(gender);
    const participantNationality = nationality ? getCountryName(nationality) : "";
    const participantTimeTaken = calculateTimeTaken(response.startTime, response.endTime);
    const participantTaskStatus =
      response.status === RESULT_SUCCESS ? "Completed" : response.status === RESULT_SCREENOUT ? "Screened Out" : "Abandoned";

    let participantTotalCardSorted = 0;
    if (response.cardSort && response.cardSort.categories) {
      for (let i in response.cardSort.categories) participantTotalCardSorted += response.cardSort.categories[i].length;
    }

    let participantDevice = "NA";
    if (userAgent) {
      const { os, browser } = Bowser.parse(userAgent);
      participantDevice = getDeviceDetails(os, browser);
    }

    participantsData.push([
      participantSNo,
      participantName,
      participantGender,
      participantNationality,
      participantTimeTaken,
      participantTaskStatus,
      participantTotalCardSorted + "/" + totalCardsInTest,
      participantDevice,
    ]);
  });

  // Raw Data Creation
  const rawDataHeader = [
    "S. No.",
    "Participant",
    "Card Index",
    "Card Label",
    "Category Label",
    "Complete",
    "Category Reason",
    "Card Reason",
    "Timestamp",
    "Sorted Position",
  ];
  const { rawData } = responses.reduce(
    ({ index, rawData }, res, i) => {
      if (res.cardSort && res.cardSort.categories) {
        const { name } = res.participant;
        const { cards, categories } = test.card_sort;
        const resCategories = res.cardSort.categories;

        const pData = Object.keys(resCategories).reduce((data, key) => {
          const category = categories.find((cat) => cat._id === key);
          const cardss = resCategories[key].map((c) => ({ ...c, cardIndex: cards.findIndex((crd) => crd._id === c.id) }));

          if (cardss.length > 0) {
            const abcs = cardss.map((c) => {
              index++;
              const timestamp = moment(c.timestamp).format("YYYY-MM-DD h:mm:ss");
              return [
                index,
                name,
                c.cardIndex + 1,
                c.name,
                category?.name || "-",
                1,
                category?.namingReason || "-",
                c?.reason || "-",
                timestamp,
                c.index + 1,
              ];
            });

            return [...data, ...abcs];
          }

          return data;
        }, []);

        return { index, rawData: [...rawData, ...pData] };
      }

      return { index, rawData };
    },
    { index: 0, rawData: [rawDataHeader] }
  );

  // Overview (Needs to be calculated after Participants)
  overviewData = [
    ["Name: " + test.name],
    ["Status: " + getTestStatuslabelText(test.status)],
    ["Type: " + getTestTypeText(test.type)],
    ["Launched on: " + moment(test.startTime).format("DD MMMM YYYY")],
    ["Expire on: " + moment(test.endTime).format("DD MMMM YYYY")],
    [null],
    ["Completion Rate"],
    ["Total Responses: " + responses.length, "Completed: " + completed, "Abandoned: " + abandoned, "Screened Out: " + screenOut],
    [null],
    ["Time Taken"],
    [
      "Min: " + getDurationInMMSS(Math.min(...timeTakenValues)),
      "Max: " + getDurationInMMSS(Math.max(...timeTakenValues)),
      "Avg: " + getDurationInMMSS(getAvgFromArray(timeTakenValues)),
    ],
    [null],
  ];

  responses = responses.filter((resp) => resp.status !== RESULT_FAILED);

  // Categories
  categoriesData.push(["Categories", "Sorted Cards", "Frequency"]);
  let categoriesObject = {};
  test.card_sort.categories.forEach((category) => {
    const categoryId = category._id;
    const categoryName = category.name ? category.name : "Not Available";
    // let categoryNameReason = category?.namingReason;
    // if (category?.type === MERGED_CATEGORY && category.mergedCategories.length > 0) {
    //   const reasonCats = category.mergedCategories.filter((c) => c.namingReason !== "");
    //   if (reasonCats.length > 0) {
    //     categoryNameReason = reasonCats.map((c) => `${c.name}: ${c.namingReason}`).join("\n");
    //   }
    // }

    responses.forEach((response) => {
      if (
        response.cardSort &&
        response.cardSort.categories &&
        response.cardSort.categories[categoryId] &&
        response.cardSort.categories[categoryId].length
      ) {
        response.cardSort.categories[categoryId].forEach((card) => {
          const cardId = card.id;
          const cardName = card.name;

          if (categoriesObject[categoryId] === undefined) {
            categoriesObject[categoryId] = { categoryName, cards: [] };
          }

          let frequency = 1;

          const cardObject = categoriesObject[categoryId].cards.find((c) => c.cardId === cardId);
          if (cardObject) {
            frequency = cardObject.frequency !== undefined ? cardObject.frequency + 1 : 1;
          }

          categoriesObject[categoryId].cards.push({ cardId, cardName, frequency });
        });
      }
    });
  });
  Object.keys(categoriesObject).forEach((categoryId) => {
    const category = categoriesObject[categoryId];
    category.cards.forEach((card, indx) => {
      let obj;
      if (indx === 0) obj = [category.categoryName, card.cardName, card.frequency];
      else obj = ["", card.cardName, card.frequency];
      categoriesData.push(obj);
    });
    // categoriesData.push([null]);
  });

  // Cards (Needs to be calculated after Categories)
  cardsData.push(["Cards", "Sorted Categories", "Frequency"]);
  let cardsObject = {};
  Object.keys(categoriesObject).forEach((categoryId) => {
    const category = categoriesObject[categoryId];
    const categoryName = category.categoryName;

    category.cards.forEach((card) => {
      const cardId = card.cardId;
      const cardName = card.cardName;

      if (cardsObject[cardId] === undefined) {
        cardsObject[cardId] = {
          cardName: cardName,
          categories: [],
        };
      }

      let frequency = 1;

      const categoryObject = cardsObject[cardId].categories.find((c) => c.categoryId === categoryId);
      if (categoryObject) {
        frequency = categoryObject.frequency !== undefined ? categoryObject.frequency + 1 : 1;
      }

      cardsObject[cardId].categories.push({
        categoryId: categoryId,
        categoryName: categoryName,
        frequency: frequency,
      });
    });
  });
  Object.keys(cardsObject).forEach((cardId) => {
    const card = cardsObject[cardId];
    card.categories.forEach((category, indx) => {
      let obj;
      if (indx === 0) obj = [card.cardName, category.categoryName, category.frequency];
      else obj = ["", category.categoryName, category.frequency];
      cardsData.push(obj);
    });
    // cardsData.push([null]);
  });

  // Result Matrix
  const { categories, result } = calculateResultMatrix(responses, test, true);
  let categoryIds = [];

  let rmHeaders = [""];
  categories.forEach((category) => {
    const categoryName = category.name ? category.name : "Not Available";
    const categoryId = category._id;

    categoryIds.push(categoryId);
    rmHeaders.push(categoryName);
  });
  resultMatrixData.push(rmHeaders);

  result.forEach((card) => {
    let innerData = [];

    categoryIds.forEach((categoryId) => {
      const category = card.categories.find((c) => c._id === categoryId);
      const frequency = category ? category.frequency : "";
      const popularPlacementMatrix = category ? " (" + category.popularPlacementMatrix + "%)" : "";
      innerData.push(frequency + popularPlacementMatrix);
    });

    resultMatrixData.push([card.name, ...innerData]);
  });

  // Categorisation Confidence
  const cardsTravelHistory = calculateConfidenceInSorting(responses);

  categorisationConfidenceData.push(["Cards", "Confidence in sorting", "Number of times placed in Unclear Category", ""]);

  result.forEach((card) => {
    const unclearCatData = card.categories.find((category) => category.type === UNCLEAR_CATEGORY);
    let unclearSortedFrequency = 0;
    if (unclearCatData) {
      unclearSortedFrequency = unclearCatData.frequency;
    }

    const confidence = cardsTravelHistory[card._id] ? cardsTravelHistory[card._id].confidence : "";
    categorisationConfidenceData.push([card.name, confidence, unclearSortedFrequency]);
  });

  // Writing Report into XLSX Sheet
  let wb = XLSX.utils.book_new();

  let rawDataWS = XLSX.utils.aoa_to_sheet(rawData);
  let overviewWS = XLSX.utils.aoa_to_sheet(overviewData);
  let participantsWS = XLSX.utils.aoa_to_sheet(participantsData);
  let cardsWS = XLSX.utils.aoa_to_sheet(cardsData);
  let categoriesWS = XLSX.utils.aoa_to_sheet(categoriesData);
  let resultMatrixWS = XLSX.utils.aoa_to_sheet(resultMatrixData);
  let categorisationConfidenceWS = XLSX.utils.aoa_to_sheet(categorisationConfidenceData);

  XLSX.utils.book_append_sheet(wb, rawDataWS, "Raw Data");
  XLSX.utils.book_append_sheet(wb, overviewWS, "Overview");
  XLSX.utils.book_append_sheet(wb, participantsWS, "Participants");
  XLSX.utils.book_append_sheet(wb, cardsWS, "Cards");
  XLSX.utils.book_append_sheet(wb, categoriesWS, "Categories");
  XLSX.utils.book_append_sheet(wb, resultMatrixWS, "Result Matrix");
  XLSX.utils.book_append_sheet(wb, categorisationConfidenceWS, "Categorisation Confidence");

  // Questionnaire
  if (isSurveyEnabled(test)) {
    surveyTypes().forEach((item) => {
      if (test && test.settings && test.settings.survey[item.key].include) {
        const excelData = getQuestionnaireExcelData(responses, test, item.key, update);
        if (excelData) XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(excelData), item.title + " Result");
      }
    });
  }

  XLSX.writeFile(wb, test.name + ".xlsx");
};

// Include/exclude responses in calculation
export const incExcResponses = (testId, includeData) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/api/response/${testId}/include`, includeData);

    const { test, response } = data;
    dispatch({ type: REPORT_LOAD, payload: { test, ...createDesiredResponse(response) } });
    dispatch(setAlert(`Some responses are ${includeData.included ? "added in" : "removed from"} counting the result.`, "success"));
  } catch (error) {
    console.log(error, error.response);
    if (error && error.response && error.response.data && error.response.status) {
      if (error.response.status === 401) dispatch({ type: AUTH_ERROR });
      else dispatch(setAlert(error.response.data.msg, "error"));
    }
  }
};

// Delete responses
export const deleteResponses = (testId, response_ids) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/api/response/${testId}/batch_delete`, { response_ids });

    const { test, response } = data;
    dispatch({ type: REPORT_LOAD, payload: { test, ...createDesiredResponse(response) } });
    dispatch(setAlert(`Selected responses are deleted successfully.`, "success"));
  } catch (error) {
    console.log(error, error.response);
    if (error && error.response && error.response.data && error.response.status) {
      if (error.response.status === 401) dispatch({ type: AUTH_ERROR });
      else dispatch(setAlert(error.response.data.msg, "error"));
    }
  }
};
