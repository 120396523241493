export const SET_MESSAGE = "SET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const USER_STATE_UPDATE = "USER_STATE_UPDATE";

export const TEST = {
  SAVING: "TEST_SAVING",
  LOADED: "TEST_LOADED",
  UPDATED: "TEST_UPDATED",
  CREATED: "TEST_CREATED",
  DELETED: "TEST_DELETED",
  LOADING: "TEST_LOADING",
  TASK_SAVING: "TASK_SAVING",
  UPDATE_TREE: "UPDATE_TREE",
  DUPLICATED: "TEST_DUPLICATED",
  SAVE_STATE: "TEST_SAVE_STATE",
};

export const SURVEY_LOAD = "SURVEY_LOAD";
export const SAVE_SURVEY_STATE = "SAVE_SURVEY_STATE";
export const SURVEY_ADD_QUESTION = "SURVEY_ADD_QUESTION";

export const REPORT_LOAD = "REPORT_LOAD";
export const REPORT_RESET = "REPORT_RESET";
export const REPORT_FILTER = "REPORT_FILTER";
export const REPORT_LOADING = "REPORT_LOADING";
export const REPORT_FILTER_SET = "REPORT_FILTER_SET";

export const LOAD_PLANS = "LOAD_PLANS";
export const LOAD_PLAN_PREVIEW = "LOAD_PLAN_PREVIEW";
export const ERROR_PLAN_PREVIEW = "ERROR_PLAN_PREVIEW";

export const STUDY = {
  LOAD: "LOAD",
  UPDATE: "UPDATE",
  COMPLETE: "COMPLETE",
  ATTEMPTED: "ATTEMPTED",
  MOVE_NEXT: "MOVE_NEXT",
  MOVE_PREV: "MOVE_PREV",
  SURVEY_LOAD: "SURVEY_INIT",
  SURVEY_SAVE: "SURVEY_SAVE",
  TASK_SAVING: "STYDY_TASK_SAVING",
};
export const SSO_FETCH = "SSO_FETCH";
export const SSO_ERROR = "SSO_ERROR";
export const SSO_UPDATE = "SSO_UPDATE";
export const SSO_DELETED = "SSO_DELETED";
export const SSO_STATE_UPDATE = "SSO_STATE_UPDATE";

export const TEST_ITEMS_KEYS = {
  CARDS: "cards",
  RECRUIT: "recruit",
  SETUP: "test-setup",
  MESSAGES: "messages",
  DETAILS: "test-details",
  CATEGORIES: "categories",
  QUESTIONNAIRE: "questionnaire",
};

export const NOTIFICATION = { SET: "NOTIFICATION_SET", REMOVE: "NOTIFICATION_REMOVE" };

export const SURVEY_TYPES = { SCREENER: "SCREENER", PRE_SURVEY: "PRE_SURVEY", POST_SURVEY: "POST_SURVEY" };
