// Import Icons
import uxarmyLogo from "../assets/images/uxarmy-logo.svg";
import emptyTest from "../assets/images/test-empty-2x.png";
import testSetup from "../assets/images/test-setup.svg";
import testDetails from "../assets/images/test-details.svg";
import messages from "../assets/images/messages.svg";
import recruit from "../assets/images/recruit.svg";
import questionnaire from "../assets/images/questionnaire.svg";
import importIcon from "../assets/images/import.svg";
import closeIcon from "../assets/images/close.svg";
import activeTestIcon from "../assets/images/status/on-going.svg";
import inactiveTestIcon from "../assets/images/status/inactive.svg";
import draftTestIcon from "../assets/images/status/draft.svg";
import completedTestIcon from "../assets/images/status/completed.svg";
import activeTestLabel from "../assets/images/status/label/active.png";
import inactiveTestLabel from "../assets/images/status/label/inactive.png";
import draftTestLabel from "../assets/images/status/label/draft.png";
import completedTestLabel from "../assets/images/status/label/completed.png";
import reportOverviewIcon from "../assets/images/overview.svg";
import reportAnalysisIcon from "../assets/images/analysis.svg";
import genderMale from "../assets/images/male@2x.png";
import genderFemale from "../assets/images/female@2x.png";
import testcomplete from "../assets/images/test-complete@2x.png";
import defineAnswer from "../assets/images/define-answer.png";
import selectAnswer from "../assets/images/select-answer.png";
import cautionIcon from "../assets/images/caution.svg";
import confirmationIcon from "../assets/images/confirmation.svg";
import instructionsIcon from "../assets/images/instructions.svg";
import error404 from "../assets/images/error404.svg";
import ohSnapIcon from "../assets/images/oh-snap.svg";
import firstClick from "../assets/images/1st-click.svg";
import taskDescription from "../assets/images/task-descriptions.svg";
import treeStructure from "../assets/images/treestructure.svg";
import ohSnapReportIcon from "../assets/images/oh-snap-01.png";
import treeTestingLogo from "../assets/images/tree-testing-icon.svg";
import participatoryDesignLogo from "../assets/images/participatory-design-icon.svg";
import cardSortingLogo from "../assets/images/card-sorting-icon.svg";
import combinedShapeLogo from "../assets/images/combined-shape@2x.png";
import iterativeLogo from "../assets/images/iterative@2x.png";
import logoutLogo from "../assets/images/logout@2x.png";
import testDetailsLogo from "../assets/images/test-details@2x.png";
import toolsIcon from "../assets/images/tools@2x.png";
import arrowIcon from "../assets/images/arrow@2x.png";
import ruiIcon from "../assets/images/rui.png";
import crosshair from "../assets/images/crosshair.png";
import openCardIcon from "../assets/images/open-card@2x.png";
import closeCardIcon from "../assets/images/close-card@2x.png";
import mixedCardIcon from "../assets/images/mixed-card@2x.png";
import blankImage from "../assets/images/image@2x.png";
import unionIcon from "../assets/images/union.svg";
import maleIcon from "../assets/images/male.svg";
import femaleIcon from "../assets/images/female.svg";
import forward from "../assets/images/forward.svg";
import backward from "../assets/images/backward.svg";
import sibling from "../assets/images/sibling.svg";
import filter from "../assets/images/filter.svg";
import firstStep from "../assets/images/1st-step.png";
import secondStep from "../assets/images/2nd-step.png";
import thirdStep from "../assets/images/3rd-step.png";
import firstStepGray from "../assets/images/1st-step_gray.svg";
import secondStepGray from "../assets/images/2nd-step_gray.svg";
import thirdStepGray from "../assets/images/3rd-step_gray.svg";
import comment from "../assets/images/comment.png";
import commentRed from "../assets/images/comment_red.png";
import commentActive from "../assets/images/comment_active.png";
import editIcon from "../assets/images/edit-icon.png";
import sortIcon from "../assets/images/sort.svg";
import categoryIcon from "../assets/images/category.svg";
import importCSVIcon from "../assets/images/import-csv.svg";
import catConfIcon from "../assets/images/cat-conf.svg";
import sorrySvg from "../assets/images/sorry.svg";
import thankyouSvg from "../assets/images/thankyou.svg";
import alrightSvg from "../assets/images/alright.svg";
import dragNdrop from "../assets/images/drag_drop.png";
import ttTourTip from "../assets/images/tt-tour-tip.gif";
import gearIcon from "../assets/images/gear-icon.png";
import loginSidebar from "../assets/images/login_sidebar.svg";
import copyIcon from "../assets/images/copy.svg";

// Export Icons
export const UXARMY_LOGO = uxarmyLogo;
export const EMPTY_TEST_ICON = emptyTest;
export const TEST_SETUP_ICON = testSetup;
export const TEST_DTAILS_ICON = testDetails;
export const TEST_MESSAGES_ICON = messages;
export const TEST_RECRUIT_ICON = recruit;
export const TEST_QUESTIONNAIRE_ICON = questionnaire;
export const TREE_IMPORT_ICON = importIcon;
export const CLOSE_ICON = closeIcon;
export const ACTIVE_TEST_ICON = activeTestIcon;
export const INACTIVE_TEST_ICON = inactiveTestIcon;
export const DRAFT_TEST_ICON = draftTestIcon;
export const COMPLETED_TEST_ICON = completedTestIcon;
export const ACTIVE_TEST_LABEL = activeTestLabel;
export const INACTIVE_TEST_LABEL = inactiveTestLabel;
export const DRAFT_TEST_LABEL = draftTestLabel;
export const COMPLETED_TEST_LABEL = completedTestLabel;
export const OVERVIEW_ICON = reportOverviewIcon;
export const ANALYSIS_ICON = reportAnalysisIcon;
export const GENDER_MALE = genderMale;
export const GENDER_FEMALE = genderFemale;
export const TEST_COMPLETE = testcomplete;
export const DEFINE_ANSWER = defineAnswer;
export const SELECT_ANSWER = selectAnswer;
export const CAUTION_ICON = cautionIcon;
export const CONFIRMATION_ICON = confirmationIcon;
export const INSTRUCTIONS_ICON = instructionsIcon;
export const ERROR_404_ICON = error404;
export const OH_SNAP_ICON = ohSnapIcon;
export const FIRST_CLICK = firstClick;
export const TASK_DESCRIPTION = taskDescription;
export const TREE_STRUCTURE = treeStructure;
export const OH_SNAP_REPORT_ICON = ohSnapReportIcon;
export const TREE_TESTING = treeTestingLogo;
export const PARTICIPATORY = participatoryDesignLogo;
export const CARDSORTING_ICON = cardSortingLogo;
export const DASHBOARD = combinedShapeLogo;
export const ITERATIVE = iterativeLogo;
export const LOGOUT = logoutLogo;
export const TEST_DETAILS = testDetailsLogo;
export const TOOLS_ICON = toolsIcon;
export const ARROW_ICON = arrowIcon;
export const RUI_ICON = ruiIcon;
export const CROSSHAIR = crosshair;
export const OPENCARD_ICON = openCardIcon;
export const CLOSECARD_ICON = closeCardIcon;
export const MIXEDCARD_ICON = mixedCardIcon;
export const BLANK_IMAGE = blankImage;
export const UNION_ICON = unionIcon;
export const MALE_ICON = maleIcon;
export const FEMALE_ICON = femaleIcon;
export const FORWARD = forward;
export const BACKWARD = backward;
export const SIBLING = sibling;
export const FILTER = filter;
export const FIRST_STEP = firstStep;
export const SECOND_STEP = secondStep;
export const THIRD_STEP = thirdStep;
export const FIRST_STEP_GRAY = firstStepGray;
export const SECOND_STEP_GRAY = secondStepGray;
export const THIRD_STEP_GRAY = thirdStepGray;
export const COMMENT = comment;
export const COMMENT_RED = commentRed;
export const COMMENT_ACTIVE = commentActive;
export const EDIT_ICON = editIcon;
export const SORT_ICON = sortIcon;
export const CATEGORY_ICON = categoryIcon;
export const IMPORT_CSV_ICON = importCSVIcon;
export const CAT_CONF_ICON = catConfIcon;
export const SORRY_SVG = sorrySvg;
export const THANKYOU_SVG = thankyouSvg;
export const ALRIGHT_SVG = alrightSvg;
export const DRAGDROP_IMG = dragNdrop;
export const TT_TOUR_TIP = ttTourTip;
export const GEAR_ICON = gearIcon;
export const LOGIN_SIDEBAR = loginSidebar;
export const COPY_ICON = copyIcon;
