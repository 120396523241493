import { EyeOutlined } from "@ant-design/icons";
import { useStudyContext } from "../StudyProvider";

export default function PreviewText() {
  const { t, isPreview } = useStudyContext();

  if (!isPreview) return null;

  return (
    <div className='preview-mode p-2 font-size-16px d-flex text-center'>
      <EyeOutlined />
      <p className='m-0 flex-auto'>{t("PREVIEW_MODE_TEXT")}</p>
    </div>
  );
}
