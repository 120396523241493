import PropTypes from "prop-types";
import { App } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { resetAlert } from "../../actions/alert";

const Notification = ({ notifyObj, resetAlert }) => {
  const { notification } = App.useApp();

  useEffect(() => {
    if (notifyObj?.message || notifyObj?.description) {
      notification.open({
        ...notifyObj,
        onClose: resetAlert,
        type: notifyObj?.type || "success",
        duration: notifyObj?.duration || 3.5,
      });
    }
  }, [notifyObj, notification, resetAlert]);
};

const mapStateToProps = ({ notification }) => ({ notifyObj: notification });

Notification.propTypes = {
  resetAlert: PropTypes.func,
  notifyObj: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { resetAlert })(Notification);
