import { Suspense, lazy } from "react";
import UXLoader from "../components/layouts/UXLoader";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<UXLoader useFullHeight />}>
      <Component {...props} />
    </Suspense>
  );
};

// DASHBOARD
export const Settings = Loadable(lazy(() => import("../components/settings")));
export const Dashboard = Loadable(lazy(() => import("../components/dashboard")));
export const TestReport = Loadable(lazy(() => import("../components/test-report/")));
export const TestCreation = Loadable(lazy(() => import("../components/test-creation")));
export const Subscriptions = Loadable(lazy(() => import("../components/subscriptions")));
export const TestReportPieTree = Loadable(lazy(() => import("../components/test-report/tree-test/PieTree")));
export const TestStudy = Loadable(lazy(() => import("../components/test-study")));
export const CardSortBegin = Loadable(lazy(() => import("../components/test-study/CardSortBegin")));
export const TreeStudyBegin = Loadable(lazy(() => import("../components/test-study/TreeStudyBegin")));
export const ParticipateStudyBegin = Loadable(lazy(() => import("../components/test-study/ParticipateStudyBegin")));
export const StudyInterrupted = Loadable(lazy(() => import("../components/test-study/StudyInterrupted")));
export const QuestionnaireBegin = Loadable(lazy(() => import("../components/test-study/QuestionnaireBegin")));
export const QuestionnaireThankYou = Loadable(lazy(() => import("../components/test-study/layouts/QuestionnaireThankYou")));

// ERROR
export const Message = Loadable(lazy(() => import("../components/layouts/UXMessage")));
export const Page404 = Loadable(lazy(() => import("../components/layouts/NotFound")));
