import { LOGOUT, AUTH_ERROR, USER_LOADED, USER_STATE_UPDATE } from "../actions/types";

const initialState = { isAuthenticated: false, loading: false, user: null, error: null };

const authReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case USER_STATE_UPDATE:
      return { ...state, ...payload };
    case AUTH_ERROR:
      return { ...state, isAuthenticated: false, loading: false, ...payload };
    case USER_LOADED:
      return { ...state, isAuthenticated: true, loading: false, user: payload, error: null };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
