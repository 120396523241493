import { TEST } from "../actions/types";

const initialState = { tests: [], test: null, loading: true, isSaving: false, isSavingTask: false, duplicateTestId: null };

const testReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TEST.LOADED:
      return { ...state, tests: payload, loading: false };
    case TEST.SAVE_STATE:
      return { ...state, test: payload };
    case TEST.CREATED:
    case TEST.UPDATED:
      return { ...state, test: payload, loading: false, isSaving: false, duplicateTestId: null };
    case TEST.SAVING:
      return { ...state, isSaving: payload };
    case TEST.LOADING:
      return { ...state, loading: payload };
    case TEST.TASK_SAVING:
      return { ...state, isSavingTask: payload };
    case TEST.DELETED:
      return { ...state, tests: state.tests.filter((test) => test._id !== payload), loading: false, duplicateTestId: null };
    case TEST.UPDATE_TREE:
      return { ...state, test: { ...state.test, tree: payload } };
    case TEST.DUPLICATED:
      return { ...state, tests: [payload, ...state.tests], loading: false, duplicateTestId: payload._id };
    default:
      return state;
  }
};

export default testReducer;
