import axios from "../utills/axios";
import { STUDY } from "./types";
import { uniqueID, shuffleArray } from "../utills/common";
import { shuffleSurveyQuestionOptions } from "../utills/testFunctions";
import { CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from "./constants";
import { CARD_TYPE_CLOSE, TESTER_CATEGORY, TEST_TYPE_CS, TEST_TYPE_TT, TEST_TYPE_PD } from "./constants";

// Verify Test
export const verifyTest = (testId, isPreview) => async (dispatch) => {
  dispatch({ type: STUDY.COMPLETE });

  try {
    const { data: test } = await axios.get(`/api/test/check/${testId}/preview/${isPreview}`);
    if (isPreview) {
      let error = "";
      if (test.type === TEST_TYPE_TT && !test.tree) error = "Tree is not created for this test. Please create the Tree first.";
      else if ((test.type === TEST_TYPE_TT || test.type === TEST_TYPE_PD) && test.tasks.length === 0)
        error = "Tasks are not created for this test. Please create the Tasks first.";
      else if (test.type === TEST_TYPE_CS) {
        const { cards, categories } = test.card_sort;
        const { type } = test.settings.card_sort;
        if (cards.length === 0) error = "Cards are not created for this test. Please create the cards first.";
        if (type === CARD_TYPE_CLOSE && categories.length === 0) {
          error = "Categories are not created for this test. Please create the categories first.";
        }
      }

      if (error) return dispatch({ type: STUDY.LOAD, payload: { error } });
    }

    // Branding
    const branding = { logo: null, color: null };
    if (test.account && test.account.branding) {
      const { logo, color } = test.account.branding;
      branding.color = color.primary ? color.primary : "";
      branding.logo = test.settings.include_logo && logo.length > 0 ? logo[0].path : "";
    }

    dispatch({ type: STUDY.LOAD, payload: { test, branding, isPreview } });
  } catch (error) {
    let payload = {};
    console.log(error);

    const overQuotaUrl = error && error.response && error.response.data.url ? error.response.data.url : "";
    if (overQuotaUrl) payload = { overQuotaUrl };
    else if (error?.response?.data?.msg) payload = { error: error.response.data.msg };

    dispatch({ type: STUDY.LOAD, payload });
  }
};

// Save Participant
export const saveParticipant = (participant, testId, isPreview) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/response/${testId}`, JSON.stringify({ participant, isPreview }));

    let { test, response_id } = res.data;
    const responseId = isPreview ? `res_${new Date().getTime()}` : response_id;
    if (test.type === TEST_TYPE_TT && test.settings && test.settings.shuffle_tasks) {
      test = { ...test, tasks: shuffleArray(test.tasks) };
    }

    // Redirect URL
    if (typeof test.redirect_url === "string") {
      test.redirect_url = { completed_url: test.redirect_url, disqualified_url: "", quota_url: "" };
    }

    const queryParams = participant.queryParams;
    const redirect_url = test.redirect_url;
    if (queryParams) {
      for (let i in redirect_url) {
        if (!redirect_url[i]) continue;

        for (let j in queryParams) redirect_url[i] = redirect_url[i].replace(`{${j}}`, queryParams[j]);

        redirect_url[i] = redirect_url[i].includes("http") === false ? "http://" + redirect_url[i] : redirect_url[i];
      }
    }

    test.redirect_url = redirect_url;

    // Survey
    for (let i in test.survey) {
      const { include, shuffle } = test.settings.survey[i];
      if (include) test.survey[i] = shuffleSurveyQuestionOptions(test.survey[i], shuffle);
    }

    dispatch({
      type: STUDY.LOAD,
      payload: { participant: { ...participant, responseId }, test, questionIndex: 0, testSurveyAnswers: {} },
    });
    dispatch({ type: STUDY.UPDATE, payload: { isNew: true } });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Save Task Reponse
export const saveTaskResponse = (responseId, taskAnswers, isPreview) => async (dispatch) => {
  dispatch({ type: STUDY.TASK_SAVING, payload: true });
  if (isPreview) {
    setTimeout(() => dispatch({ type: STUDY.TASK_SAVING, payload: false }), 250);
    return;
  }

  try {
    await axios.put(`/api/response/${responseId}`, JSON.stringify({ taskAnswers, endTime: new Date().getTime() }));
    setTimeout(() => dispatch({ type: STUDY.TASK_SAVING, payload: false }), 250);
  } catch (error) {
    console.log(error);
  }
};

// Mark Test Complete
export const markComplete =
  (responseId, isPreview, payload = null) =>
  async (dispatch) => {
    dispatch({ type: STUDY.TASK_SAVING, payload: true });

    if (isPreview) {
      setTimeout(() => {
        if (payload) dispatch({ type: STUDY.UPDATE, payload });
        dispatch({ type: STUDY.TASK_SAVING, payload: false });
      }, 250);
      return true;
    }

    try {
      // Save final response one last time
      if (payload && payload.cardSort && payload.cardSort.userResponse) {
        const finalResponse = { cardSort: payload.cardSort.userResponse, endTime: new Date().getTime() };
        await axios.put(`/api/response/${responseId}`, JSON.stringify(finalResponse));
      }

      await axios.put(`/api/response/${responseId}/complete`);
      if (payload) dispatch({ type: STUDY.UPDATE, payload });
      dispatch({ type: STUDY.ATTEMPTED });

      dispatch({ type: STUDY.TASK_SAVING, payload: false });
      return true;
    } catch (error) {
      console.log(error);
    }
  };

// Reset State
export const resetStudy = () => (dispatch) => dispatch({ type: STUDY.COMPLETE });

// Update Test State
export const updateState = (payload) => (dispatch) => dispatch({ type: STUDY.UPDATE, payload });

export const updateTest = (test, testData) => (dispatch) => {
  const payload = { test };
  if (testData) payload.testData = testData;
  dispatch({ type: STUDY.LOAD, payload });
};

// Save Card Sort Response
export const saveResponse = async (responseId, cardSort) => {
  await axios.put(`/api/response/${responseId}`, JSON.stringify({ cardSort, endTime: new Date().getTime() }));
};

// Create Tester Category
export const createCategory = (responseId, isPreview) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (isPreview) {
        return resolve({ status: 1, type: TESTER_CATEGORY, desc: "", image: [], _id: uniqueID(), response: responseId });
      }

      const result = await axios.put(`/api/response/category/${responseId}`, { action: CREATE_CATEGORY, category: { name: "" } });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Create Tester Category
export const deleteCategory = (responseId, category) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.put(`/api/response/category/${responseId}`, { action: DELETE_CATEGORY, category });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Create Tester Category
export const updateCategory = (responseId, category) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.put(`/api/response/category/${responseId}`, { action: UPDATE_CATEGORY, category });
      resolve(result.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Load Surveys
export const loadSurvey = (payload) => (dispatch) => dispatch({ type: STUDY.SURVEY_LOAD, payload });

// Save Question Answer
export const saveQuestionAnswer = (payload) => (dispatch) => dispatch({ type: STUDY.SURVEY_SAVE, payload });

export const onBackToPrevious = () => (dispatch) => {
  dispatch({ type: STUDY.TASK_SAVING, payload: true });
  setTimeout(() => dispatch({ type: STUDY.MOVE_PREV }), 250);
};

export const onMoveToNext = (testSurveyAnswers, responseId, type, isPreview) => async (dispatch) => {
  try {
    dispatch({ type: STUDY.TASK_SAVING, payload: true });
    if (!isPreview) {
      await axios.put(`/api/response/${responseId}`, { testSurveyAnswers });
      dispatch({ type: STUDY.MOVE_NEXT, payload: type });
    } else setTimeout(() => dispatch({ type: STUDY.MOVE_NEXT, payload: type }), 250);
  } catch (error) {
    console.log(error);
  }
};

// Mark Screen Failed
export const markScreenFailed =
  (responseId, isPreview, payload = null) =>
  async (dispatch) => {
    dispatch({ type: STUDY.TASK_SAVING, payload: true });

    if (isPreview) {
      setTimeout(() => {
        if (payload) dispatch({ type: STUDY.UPDATE, payload });
        dispatch({ type: STUDY.TASK_SAVING, payload: false });
      }, 250);
      return true;
    }

    try {
      await axios.put(`/api/response/${responseId}/survey/screener/fail`);
      if (payload) dispatch({ type: STUDY.UPDATE, payload });
      dispatch({ type: STUDY.ATTEMPTED });
      dispatch({ type: STUDY.TASK_SAVING, payload: false });
      return true;
    } catch (error) {
      console.log(error);
    }
  };
