import { useRoutes } from "react-router-dom";
import AuthGuard from "../components/auth/AuthGuard";
import StudyLayout from "../components/test-study/StudyLayout";
import TestLayout from "../components/test-creation/TestLayout";
import ReportLayout from "../components/test-report/ReportLayout";
import DashboardLayout from "../components/dashboard/DashboardLayout";

import {
  Page404,
  Message,
  Settings,
  Dashboard,
  TestStudy,
  TestReport,
  TestCreation,
  Subscriptions,
  TestReportPieTree,
  CardSortBegin,
  TreeStudyBegin,
  ParticipateStudyBegin,
  StudyInterrupted,
  QuestionnaireBegin,
  QuestionnaireThankYou,
} from "./elements";

export default function Router() {
  return useRoutes([
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Dashboard />, index: true },
        { path: ":menuType", element: <Dashboard /> },
        { path: "settings", element: <Settings /> },
        { path: "subscriptions", element: <Subscriptions /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <TestLayout />
        </AuthGuard>
      ),
      path: "tests",
      children: [
        { path: ":testId", element: <TestCreation /> },
        { path: ":testId/:action", element: <TestCreation /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <ReportLayout />
        </AuthGuard>
      ),
      path: "tests/report/",
      children: [
        { path: ":testId", element: <TestReport /> },
        { path: ":testId/pie-tree/:activeTaskIndex", element: <TestReportPieTree /> },
        { path: ":testId/pie-tree/:activeTaskIndex/:isNewPage", element: <TestReportPieTree /> },
      ],
    },
    {
      element: <StudyLayout />,
      path: "study",
      children: [
        { element: <TestStudy />, index: true },
        { path: ":testId", element: <TestStudy /> },
        { path: ":testId/:action", element: <TestStudy /> },
        { path: "treebegin", element: <TreeStudyBegin /> },
        { path: "cardsortingbegin", element: <CardSortBegin /> },
        { path: "participatebegin", element: <ParticipateStudyBegin /> },
        { path: "interrupted", element: <StudyInterrupted /> },
        { path: "questionnaire/thank-you", element: <QuestionnaireThankYou /> },
        { path: "questionnaire/thank-you/:complete", element: <QuestionnaireThankYou /> },
        { path: "questionnaire/:type", element: <QuestionnaireBegin /> },
      ],
    },
    { path: "*", element: <Page404 /> },
    { path: "message", element: <Message /> },
  ]);
}
