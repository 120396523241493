import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// translation files
import thai from "../locales/thai.json";
import dutch from "../locales/dutch.json";
import polish from "../locales/polish.json";
import german from "../locales/german.json";
import french from "../locales/french.json";
import korean from "../locales/korean.json";
import italian from "../locales/italian.json";
import spanish from "../locales/spanish.json";
import vietnam from "../locales/vietnam.json";
import filipino from "../locales/filipino.json";
import bahasa_id from "../locales/bahasa_id.json";
import en_us from "../locales/english_us.json";
import simplifiedCH from "../locales/simplifiedCH.json";
import traditionalCH from "../locales/traditionalCH.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en_us",
    fallbackLng: "en_us",
    resources: {
      thai: { translation: thai },
      en_us: { translation: en_us },
      dutch: { translation: dutch },
      polish: { translation: polish },
      german: { translation: german },
      french: { translation: french },
      korean: { translation: korean },
      italian: { translation: italian },
      spanish: { translation: spanish },
      vietnam: { translation: vietnam },
      filipino: { translation: filipino },
      bahasa_id: { translation: bahasa_id },
      simplifiedCH: { translation: simplifiedCH },
      traditionalCH: { translation: traditionalCH },
    },
    languages: [
      { key: "en_us", text: "English (US)" },
      { key: "simplifiedCH", text: "简体字 (Chinese Simplified)" },
      { key: "traditionalCH", text: "繁體字 （Chinese Traditional)" },
      { key: "dutch", text: "Nederlands (Dutch)" },
      { key: "filipino", text: "Tagalog (Filipino)" },
      { key: "french", text: "Français (French)" },
      { key: "german", text: "Deutsch (German)" },
      { key: "bahasa_id", text: "Bahasa Indonesia (Indonesian)" },
      { key: "korean", text: "한국어 (Korean)" },
      { key: "italian", text: "italiano (Italian)" },
      { key: "spanish", text: "Español (Spanish)" },
      { key: "thai", text: "ภาษาไทย (Thai)" },
      { key: "vietnam", text: "tiếng Việt (Vietnamese)" },
      { key: "polish", text: "Polski (Polish)" },
    ],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: { escapeValue: false }, // react already safes from xss
  });

export default i18n;
