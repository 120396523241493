import PropTypes from "prop-types";
import UXArmyLogo from "./UXArmyLogo";
import CustomIcon from "../layouts/CustomIcon";
import { Link } from "react-router-dom";
import { Button, Tooltip, Typography } from "antd";
import { useAuthContext } from "../auth/AuthProvider";

const { Paragraph } = Typography;

export default function TopNavbar({ showLogoOnly = false }) {
  const { user, logout, loading, isAuthenticated } = useAuthContext();

  return (
    <div className='top-navbar pl-2 pr-2 flex-item space-between'>
      <div className='flex-item gap-2'>
        <UXArmyLogo />

        {isAuthenticated && !loading && !showLogoOnly && (
          <Link to='/' className='text-underline text-primary font-size-20px font-bold ml-4'>
            All Tests
          </Link>
        )}
      </div>

      {!showLogoOnly && isAuthenticated && user && (
        <div className='flex-item gap-2'>
          <div>
            <Paragraph strong className='m-0 montserrat'>
              {user.fullName}
            </Paragraph>
            <Paragraph strong type='secondary' className='m-0 font-size-12px montserrat'>
              {user.email}
            </Paragraph>
          </div>

          <Tooltip title='Logout'>
            <Button type='text' icon={<CustomIcon name='logout' style={{ height: 24, width: 26 }} />} onClick={logout} />
          </Tooltip>
        </div>
      )}
    </div>
  );
}

TopNavbar.propTypes = { showLogoOnly: PropTypes.bool };
