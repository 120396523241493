import {
  ACTIVE_TEST_ICON,
  INACTIVE_TEST_ICON,
  DRAFT_TEST_ICON,
  COMPLETED_TEST_ICON,
  ACTIVE_TEST_LABEL,
  INACTIVE_TEST_LABEL,
  DRAFT_TEST_LABEL,
  COMPLETED_TEST_LABEL,
  CARDSORTING_ICON,
  TREE_TESTING,
  PARTICIPATORY,
  OPENCARD_ICON,
  CLOSECARD_ICON,
  MIXEDCARD_ICON,
} from "./icons";
import {
  TEST_STATUS_ACTIVE,
  TEST_STATUS_INACTIVE,
  TEST_STATUS_DRAFT,
  TEST_STATUS_EDIT,
  TEST_STATUS_COMPLETED,
  TEST_TYPE_TT,
  TEST_TYPE_PD,
  TEST_TYPE_CS,
  TEST_DEVICE_TYPE_DESKTOP,
  TEST_DEVICE_TYPE_SMARTPHONE,
  CARD_TYPE_OPEN,
  CARD_TYPE_CLOSE,
  CARD_TYPE_HYBRID,
  MIN_CATEGORIES,
  MIN_CARDS,
  UNCLEAR_CATEGORY,
  QUEST_TYPE_TEXT,
  QUEST_TYPE_RADIO,
  QUEST_TYPE_CHECKBOX,
  // QUEST_TYPE_RATING,
  SURVEY_TYPE_SCREENER,
  SURVEY_TYPE_PRE,
  SURVEY_TYPE_POST,
  OPTION_TYPE_OTHERS,
  RESULT_FAILED,
  DIRECTION_FORWARD,
  DIRECTION_BACKWARD,
  REQUIRE_EXPLANATION_NO,
  REQUIRE_EXPLANATION_YES,
  REQUIRE_EXPLANATION_OPTIONAL,
  RESPONSES,
} from "../actions/constants";
import dayjs from "dayjs";
import { shuffleArray, sortByKey } from "./common";
import { calculateTimeTaken } from "./reportFunctions";
import CustomIcon from "../components/layouts/CustomIcon";

// Test Types
export const getAllTestStatus = () => {
  return [
    { value: TEST_STATUS_EDIT, text: "In-Edit", icon: <CustomIcon name='draft' />, label: DRAFT_TEST_LABEL },
    { value: TEST_STATUS_DRAFT, text: "Draft", icon: <CustomIcon name='draft' />, label: DRAFT_TEST_LABEL },
    { value: TEST_STATUS_ACTIVE, text: "Active", icon: <CustomIcon name='active' />, label: ACTIVE_TEST_LABEL },
    { value: TEST_STATUS_INACTIVE, text: "Inactive", icon: <CustomIcon name='inactive' />, label: INACTIVE_TEST_LABEL },
    { value: TEST_STATUS_COMPLETED, text: "Completed", icon: <CustomIcon name='completed' />, label: COMPLETED_TEST_LABEL },
  ];
};

// Test Type Text
export const getTestStatus = (type = TEST_STATUS_DRAFT) => getAllTestStatus().find((t) => t.value === type);

// Get Test Status Icon
export const getTestStatuslabelIcon = (status, icon = true) => {
  const labels = {
    [TEST_STATUS_DRAFT]: { icon: DRAFT_TEST_ICON, label: DRAFT_TEST_LABEL },
    [TEST_STATUS_ACTIVE]: { icon: ACTIVE_TEST_ICON, label: ACTIVE_TEST_LABEL },
    [TEST_STATUS_INACTIVE]: { icon: INACTIVE_TEST_ICON, label: INACTIVE_TEST_LABEL },
    [TEST_STATUS_EDIT]: { icon: DRAFT_TEST_ICON, label: DRAFT_TEST_LABEL },
    [TEST_STATUS_COMPLETED]: { icon: COMPLETED_TEST_ICON, label: COMPLETED_TEST_LABEL },
  };

  return icon ? (labels[status] ? labels[status].icon : DRAFT_TEST_ICON) : labels[status] ? labels[status].label : DRAFT_TEST_LABEL;
};

// Get Test Status Text
export const getTestStatuslabelText = (status) => {
  const labelText = {
    [TEST_STATUS_DRAFT]: "Draft",
    [TEST_STATUS_ACTIVE]: "Active",
    [TEST_STATUS_INACTIVE]: "Inactive",
    [TEST_STATUS_EDIT]: "In-Edit",
    [TEST_STATUS_COMPLETED]: "Completed",
  };

  return labelText[status] ? labelText[status] : "Draft";
};

// Test End Time Validation
export const isCorrectEndTime = (endTime) => dayjs(endTime) > dayjs();

// Returns true/false
export const isTestLaunchable = (test) => {
  if (!test || !test._id || !test.name) return false;
  if (!isCorrectEndTime(test?.endTime) || isQuestionnaireError(test) || !isCorrectResponses(test)) return false;
  if (test.type === TEST_TYPE_TT) {
    if (test.tree) {
      const rootNode = test.tree.treeData[0];
      if (rootNode.children.length === 0) return false;
    } else {
      return false;
    }
  }

  if (test.type !== TEST_TYPE_CS) {
    if (test.tasks) {
      if (test.tasks.length === 0) return false;

      if (test.type === TEST_TYPE_TT) {
        let unAnswerTasks = test.tasks.filter((task) => !task.answers || task.answers.length === 0);
        if (unAnswerTasks.length > 0) return false;
      } else if (test.type === TEST_TYPE_PD) {
        let isPDError = test.tasks.some(
          (task) => !task.markers || task.markers.length === 0 || !task.description || task.description.length === 0
        );
        if (isPDError) return false;
      }
    } else {
      return false;
    }
  } else if (test.type === TEST_TYPE_CS) {
    const { type } = test.settings.card_sort;
    if (type === CARD_TYPE_CLOSE || type === CARD_TYPE_HYBRID) {
      return !isCardsError(test) && !isCategoriesError(test);
    } else if (type === CARD_TYPE_OPEN) {
      return !isCardsError(test);
    }
  }

  return true;
};

// Test Launch Text
export const getTestLaunchText = (status) => {
  const labelText = {
    [TEST_STATUS_DRAFT]: "Launch",
    [TEST_STATUS_ACTIVE]: "Deactivate",
    [TEST_STATUS_INACTIVE]: "Launch",
    [TEST_STATUS_EDIT]: "Relaunch",
    [TEST_STATUS_COMPLETED]: "Completed",
  };

  return labelText[status] ? labelText[status] : "Launch";
};

// Test Types
export const getTestTypes = (icon2Size = 28) => {
  const CustomIcon2 = (props) => <CustomIcon {...props} style={{ height: icon2Size, width: icon2Size }} />;

  return [
    { value: TEST_TYPE_CS, text: "Card Sorting", icon: CARDSORTING_ICON, icon2: <CustomIcon2 name='cardsorting' /> },
    { value: TEST_TYPE_TT, text: "Tree Testing", icon: TREE_TESTING, icon2: <CustomIcon2 name='treetesting' /> },
    { value: TEST_TYPE_PD, text: "Participatory Design", icon: PARTICIPATORY, icon2: <CustomIcon2 name='participatory' /> },
  ];
};

// Test Type Text
export const getTestType = (type = TEST_TYPE_TT, icon2Size = 28) => getTestTypes(icon2Size).find((t) => t.value === type);

// Test Type Text
export const getTestTypeText = (type = TEST_TYPE_TT) => {
  const typeData = getTestTypes().find((t) => t.value === type);
  return typeData ? typeData.text : "";
};

// Test Device Types
export const getTestDeviceTypes = () => {
  return [
    { value: TEST_DEVICE_TYPE_DESKTOP, text: "Desktop", icon: "desktop" },
    { value: TEST_DEVICE_TYPE_SMARTPHONE, text: "Smart Phone", icon: "mobile alternate" },
  ];
};

// Test Device Type Text
export const getTestDeviceTypeText = (type = TEST_DEVICE_TYPE_SMARTPHONE) => {
  const typeData = getTestDeviceTypes().find((t) => t.value === type);
  return typeData ? typeData.text : "";
};

// Get Card Types
export const getCardTypes = () => [
  { value: CARD_TYPE_OPEN, text: "Open Card Sorting", short: "OPEN", icon: OPENCARD_ICON },
  { value: CARD_TYPE_CLOSE, text: "Closed Card Sorting", short: "CLOSED", icon: CLOSECARD_ICON },
  { value: CARD_TYPE_HYBRID, text: "Hybrid Card Sorting", short: "HYBRID", icon: MIXEDCARD_ICON },
];

// Get Card Type
export const getCardType = (type, short = false) => {
  const cardType = getCardTypes().find((c) => c.value === type);
  return cardType ? (short ? cardType.short : cardType.text) : "";
};

// Is there any errors in cards
export const isCardsError = (test) => {
  const { cards } = test.card_sort;
  // const { description } = test.settings.card_sort.card;

  if (cards.length < MIN_CARDS) return true;

  const item = cards.reduce(
    (data, card) => {
      let isError = false;
      if (data.isError) isError = true;

      const name = card.name;
      if (!name) isError = true;
      if (data.names.includes(name)) isError = true;
      // if (description && !card.desc.trim()) isError = true;
      return { isError, names: [...data.names, name] };
    },
    { isError: false, names: [] }
  );

  return item.isError;
};

// Is there any errors in categories
export const isCategoriesError = (test) => {
  const { categories } = test.card_sort;
  // const { description } = test.settings.card_sort.category;

  if (categories.length < MIN_CATEGORIES) return true;

  const item = categories.reduce(
    (data, category) => {
      if (category.type === UNCLEAR_CATEGORY) return data;

      let isError = false;
      if (data.isError) isError = true;

      const name = category.name;
      if (!name) isError = true;
      if (data.names.includes(name)) isError = true;
      // if (description && !category.desc.trim()) isError = true;
      return { isError, names: [...data.names, name] };
    },
    { isError: false, names: [] }
  );

  return item.isError;
};

// Survey question Error
export const isScreenOutError = (questions, type = SURVEY_TYPE_SCREENER) => {
  if (type !== SURVEY_TYPE_SCREENER) return false;

  return questions.reduce((result, q) => {
    if (result) return result;
    if (q.question_type !== QUEST_TYPE_TEXT && q.option.length === 0) return true;
    return false;
    // return q.option.findIndex((o) => o.screenOut) === -1; // Screen out is not required now. See Airtable 8A_july 2021 Release.
  }, false);
};

export const isQuestionnaireError = (test) => {
  if (!test._id || !test.settings) return false;

  const { survey, settings } = test;

  return Object.keys(survey).reduce((err, key) => {
    if (!err && settings.survey[key].include) {
      if (survey[key].length === 0) return true;

      return survey[key].reduce((err2, { label, option, question_type }) => {
        if (err2) return err2;

        return (
          !label ||
          ((question_type === QUEST_TYPE_RADIO || question_type === QUEST_TYPE_CHECKBOX) && option.some((o) => o.label === ""))
        );
      }, false);
    }
    return err;
  }, false);
};

// Survey Option Types
export const surveyOptionTypes = (surveyType = SURVEY_TYPE_SCREENER) => {
  const types = [
    { key: QUEST_TYPE_RADIO, text: "Radio Buttons", value: QUEST_TYPE_RADIO },
    { key: QUEST_TYPE_CHECKBOX, text: "Checkboxes", value: QUEST_TYPE_CHECKBOX },
  ];

  if (surveyType === SURVEY_TYPE_SCREENER) return types;
  return [
    { key: QUEST_TYPE_TEXT, text: "Text Answer", value: QUEST_TYPE_TEXT },
    ...types,
    // { key: QUEST_TYPE_RATING, text: 'Rating Slider', value: QUEST_TYPE_RATING },
  ];
};

// Get Survey Option Type
export const getOptionType = (surveyType = SURVEY_TYPE_SCREENER, key = QUEST_TYPE_RADIO) => {
  return surveyOptionTypes(surveyType).find((optType) => optType.key === key);
};

export const surveyTypes = () => [
  { key: SURVEY_TYPE_SCREENER, title: "Screener", value: 2, background: "bg-orange-1", bgColor: "#f2994a" },
  { key: SURVEY_TYPE_PRE, title: "Pre Survey", value: 0, background: "bg-purple-2", bgColor: "#d98df5" },
  { key: SURVEY_TYPE_POST, title: "Post Survey", value: 1, background: "bg-green-1", bgColor: "#b9c978" },
];

export const getSurveyTypeValue = (surveyKey) => {
  const surveyType = surveyTypes().find((s) => s.key === surveyKey);
  return surveyType.value;
};

export const shuffleSurveyQuestionOptions = (questions, shuffle = false) => {
  const newQuestions = shuffle ? shuffleArray(questions) : sortByKey(questions, "position");
  return newQuestions.map((quest) => {
    const { image, option, question } = quest.settings;
    let images = quest.image;
    if (images.length) images = image.shuffle ? shuffleArray(images) : sortByKey(images, "position");

    let options = quest.option;
    const otherOption = options.find((o) => o.type === OPTION_TYPE_OTHERS);
    if (option.shuffle) {
      if (otherOption) options = options.filter((o) => o.type !== OPTION_TYPE_OTHERS);
      options = shuffleArray(options);
      if (otherOption) options = [...options, otherOption];
    }

    return { ...quest, image: images, option: options, skippable: question.optional };
  });
};

export const isSurveyEnabled = (test) =>
  surveyTypes().reduce(
    (enable, item) =>
      enable ? enable : test && test.settings && test.settings.survey[item.key] && test.settings.survey[item.key].include,
    false
  );

export const getParticipantPathDetails = (participantResponse, task_id) => {
  let answer = "";
  let timeTaken = 0;
  let answerObj = [];
  let answerText = "";
  let result = RESULT_FAILED;
  let timeTakenText = "-";

  if (participantResponse.taskAnswers.length === 0) {
    answer = "-";
  } else {
    const userResult = participantResponse.taskAnswers.find((item) => item.id === task_id);
    if (userResult) {
      if (userResult.skipped && userResult.answer.length <= 1) {
        answer = "Skipped";
      } else {
        answerObj = userResult.answer;

        let obj = userResult.answer.reduce(
          (result, ans) => {
            const direction = ans.direction ? ans.direction : DIRECTION_FORWARD;

            let directionText = "";
            if (result.answer) {
              if (result.answer) {
                if (direction === DIRECTION_FORWARD)
                  directionText = (
                    <CustomIcon
                      name='forward'
                      className='valign-baseline'
                      style={{ color: "#32C18E", width: 16, margin: "0 2.5px" }}
                    />
                  );
                else if (direction === DIRECTION_BACKWARD)
                  directionText = (
                    <CustomIcon
                      name='backward'
                      className='valign-baseline'
                      style={{ color: "#FA7D36", width: 28, margin: "0 2.5px" }}
                    />
                  );
                else
                  directionText = (
                    <CustomIcon
                      name='sibling'
                      className='valign-baseline'
                      style={{ color: "#385CDA", width: 34, margin: "0 2.5px" }}
                    />
                  );
              }
            }

            let directionText2 = "";
            if (result.answerText) {
              if (direction === DIRECTION_FORWARD) directionText2 = ">>";
              else if (direction === DIRECTION_BACKWARD) directionText2 = "<<<<";
              else directionText2 = "<<-->>";
            }

            return {
              answer: (
                <span>
                  {result.answer} {directionText} {ans.label}
                </span>
              ),
              answerText: result.answerText + " " + directionText2 + " " + ans.label,
            };
          },
          { answer: "", answerText: "" }
        );

        answer = obj.answer;
        answerText = obj.answerText;
      }

      if (userResult.startTime && userResult.endTime) {
        const { startTime, endTime } = userResult;
        timeTakenText = calculateTimeTaken(new Date(startTime).toISOString(), new Date(endTime).toISOString());
        timeTaken = endTime - startTime;
      }
    } else answer = "-";
    result = userResult ? userResult.result : result;
  }

  return { answerObj, result, answer, answerText, timeTakenText, timeTaken };
};

export const explanationLabels = [
  { label: "Ask for all", value: REQUIRE_EXPLANATION_YES, desc: "Compulsory for participants to provide the reasoning" },
  { label: "Optional", value: REQUIRE_EXPLANATION_OPTIONAL, desc: "Optional for participants to provide the reasoning" },
  { label: "Do not ask", value: REQUIRE_EXPLANATION_NO, desc: "Participants will not be asked to provide any reasoning" },
];

// Test End Time Validation
export const isCorrectResponses = (test) => {
  if (!test._id || !test.settings || !test.settings.max_completed) return false;

  const { max_completed } = test.settings;
  return max_completed > 0 && max_completed <= RESPONSES.MAX_COMPLETED;
};
