import CustomIcon from "./CustomIcon";
import { redirectToYii } from "../../utills/common";
import { Tag, Dropdown, Button, Typography } from "antd";

const { Text } = Typography;

export default function ProductsMenu({ product = "" }) {
  const StyledText = ({ children, className = "" }) => (
    <Text className={`poppins color-112260 font-500 ${className}`}>{children}</Text>
  );

  const CustomLabel = ({ title, description = null }) => (
    <div className='flex-item flex-column align-items-start'>
      <StyledText>{title}</StyledText>

      {description && (
        <Text type='secondary' className='font-size-12px poppins'>
          {description}
        </Text>
      )}
    </div>
  );

  const redirectToToolbox = (path = "/") => (window.location.href = `${window.location.origin}${path}`);

  const products = [
    {
      key: "unmoderated",
      title: "Unmoderated Usability Testing",
      description: "Test app, website, or prototype usability",
      onClick: () => redirectToYii("v2/dashboard", false),
    },
    {
      key: "surveys",
      title: (
        <div className='flex-item gap-5px'>
          Surveys
          <Tag className='m-0 br-20px text-2E62E9 bg-EAEFFD border-0'>Beta</Tag>
        </div>
      ),
      description: "Quick questionnaires for handy feedback",
      onClick: () => redirectToYii("v2/surveys", false),
    },
    {
      key: "moderated",
      title: "User Interviews on DeepDive®",
      description: "Conduct in-depth live interview sessions",
      onClick: () => redirectToYii("v2/moderated", false),
    },
    {
      key: "cardsorting",
      title: "Card Sorting",
      description: "Discover mental grouping and categorizing",
      onClick: () => redirectToToolbox("/card-sorting"),
    },
    {
      key: "treetesting",
      title: "Tree Testing",
      description: "Test the information architecture of your product",
      onClick: () => redirectToToolbox("/tree-testing"),
    },
  ];

  const items = [
    {
      key: "gp",
      disabled: true,
      className: "cursor-default",
      label: <StyledText className='color-646F98 font-size-12px'>Products</StyledText>,
    },
    ...products.map(({ key, title, description, onClick }) => ({
      key,
      onClick,
      icon: <CustomIcon name={key} height={40} width={40} />,
      label: <CustomLabel title={title} description={description} />,
    })),
  ];

  const activeItem = products.find(({ key }) => key === product);

  return (
    <div className='flex-item p-1'>
      <Dropdown overlayClassName='product-switcher' menu={{ items, className: "shadow-pmenu br-10px" }} trigger={["click"]}>
        <Button type='text' size='large' shape='circle' className='h-bg-gray-2' icon={<CustomIcon name='products-menu' />} />
      </Dropdown>

      <div className='flex-item gap-1'>
        <CustomIcon name={activeItem?.key || "products-menu"} height={32} width={32} />
        <CustomLabel title={activeItem?.title || "UXArmy"} />
      </div>
    </div>
  );
}
