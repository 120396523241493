import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { surveyTypes } from "../../utills/testFunctions";
import { deactivateTest, launchTest } from "../../actions/test";
import { mergeCategories, unmergeCategories } from "../../actions/categories";
import { useEffect, useMemo, useContext, createContext, useCallback } from "react";
import { getTestDetails, exportReport, exportCardSortingReport, deleteResponses, incExcResponses } from "../../actions/report";
import {
  TEST_TYPE_TT,
  TEST_TYPE_CS,
  TEST_TYPE_PD,
  TEST_STATUS_ACTIVE,
  TEST_STATUS_INACTIVE,
  TEST_STATUS_COMPLETED,
} from "../../actions/constants";

import UXLoader from "../layouts/UXLoader";
import UXMessage from "../layouts/UXMessage";
import UXMobileView from "../layouts/UXMobleView";
import "../../assets/css/report.css";

// ReportContext to keep the latest state
const ReportContext = createContext(null);

// useReportContext to share this components state everywhere this component is imported
export const useReportContext = () => {
  const context = useContext(ReportContext);

  if (!context) throw new Error("useReportContext context must be use inside ReportProvider");

  return context;
};

const ReportProvider = ({
  report,
  children,
  launchTest,
  exportReport,
  getTestDetails,
  deactivateTest,
  deleteResponses,
  incExcResponses,
  mergeCategories,
  unmergeCategories,
  exportCardSortingReport,
}) => {
  const { testId } = useParams();
  const {
    test = null,
    error = null,
    filters = {},
    response = [],
    loading = false,
    allResponses = [],
    reportResponses = [],
    includedResponses = [],
  } = report;

  // Load Test Report
  useEffect(() => {
    getTestDetails(testId);
  }, [getTestDetails, testId]);

  const totalResponses = response.length || 0;
  const studyLink = `${window.location.origin}/study/${testId}`;

  // Is Demographics On
  const isDemographicsOn = useMemo(
    () => (test && test.enableParticipantForm !== undefined ? test.enableParticipantForm : false),
    [test]
  );

  // Is Survey Included
  const isSurveyIncluded = useCallback(
    (key) => (test ? test.settings.survey[key] && test.settings.survey[key].include : false),
    [test]
  );

  // Is Questionnaire Enabled
  const isQuestionanireEnabled = surveyTypes().reduce((enable, { key }) => (enable ? enable : isSurveyIncluded(key)), false);

  // Export Test Report
  const onReportExport = useCallback(() => {
    if (test?.type === TEST_TYPE_TT) exportReport(response, test, reportResponses, includedResponses);
    else exportCardSortingReport(response, test);
  }, [test, response, reportResponses, includedResponses, exportReport, exportCardSortingReport]);

  // Active/Deactivate Test
  const onTestActiveDeactivateToggle = useCallback(async () => {
    if (test?.status === TEST_STATUS_ACTIVE) await deactivateTest(test);
    else if (test?.status === TEST_STATUS_INACTIVE) await launchTest(test);
  }, [test, deactivateTest, launchTest]);

  // Test Status
  const testStatus = useMemo(() => {
    return {
      isActive: test?.status === TEST_STATUS_ACTIVE,
      isInactive: test?.status === TEST_STATUS_INACTIVE,
      isCompleted: test?.status === TEST_STATUS_COMPLETED,
    };
  }, [test]);

  // Test Type
  const testType = useMemo(() => {
    return {
      isTreeTest: test?.type === TEST_TYPE_TT,
      isCardSortTest: test?.type === TEST_TYPE_CS,
      isParticipatoryTest: test?.type === TEST_TYPE_PD,
    };
  }, [test]);

  const memoizedValue = useMemo(
    () => ({
      test,
      error,
      testId,
      filters,
      loading,
      testType,
      response,
      studyLink,
      testStatus,
      allResponses,
      onReportExport,
      totalResponses,
      reportResponses,
      isDemographicsOn,
      isSurveyIncluded,
      includedResponses,
      deleteResponses,
      incExcResponses,
      mergeCategories,
      unmergeCategories,
      isQuestionanireEnabled,
      onTestActiveDeactivateToggle,
    }),
    [
      test,
      error,
      testId,
      filters,
      loading,
      testType,
      response,
      studyLink,
      testStatus,
      allResponses,
      onReportExport,
      totalResponses,
      reportResponses,
      isDemographicsOn,
      isSurveyIncluded,
      includedResponses,
      deleteResponses,
      incExcResponses,
      mergeCategories,
      unmergeCategories,
      isQuestionanireEnabled,
      onTestActiveDeactivateToggle,
    ]
  );

  if (isMobile) return <UXMobileView />;
  if (error) return <UXMessage message={error} showTopNavbar={false} />;
  if (!test) return <UXLoader />;

  return <ReportContext.Provider value={memoizedValue}>{children}</ReportContext.Provider>;
};

const mapStateToProps = ({ report }) => ({ report });

ReportProvider.propTypes = {
  report: PropTypes.object,
  children: PropTypes.node.isRequired,
  launchTest: PropTypes.func.isRequired,
  exportReport: PropTypes.func.isRequired,
  getTestDetails: PropTypes.func.isRequired,
  deactivateTest: PropTypes.func.isRequired,
  deleteResponses: PropTypes.func.isRequired,
  incExcResponses: PropTypes.func.isRequired,
  mergeCategories: PropTypes.func.isRequired,
  unmergeCategories: PropTypes.func.isRequired,
  exportCardSortingReport: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  launchTest,
  exportReport,
  getTestDetails,
  deactivateTest,
  deleteResponses,
  incExcResponses,
  mergeCategories,
  unmergeCategories,
  exportCardSortingReport,
})(ReportProvider);
