import * as XLSX from "xlsx";
import { uniqueID } from "./common";
import { getTreeArray } from "../actions/report";

export const findNode = (node, nodeId, callback) => {
  if (node.id === nodeId) {
    callback(node);
    return;
  }
  if (node.children) node.children.forEach((element) => findNode(element, nodeId, callback));
};

// Get Node Path
export const getNodePath = (treeData, nodeId, getPathString = false) => {
  const rootNode = treeData[0];
  const getNodeItem = (node) => (item = node);

  let item;
  findNode(rootNode, nodeId, getNodeItem);

  let path = [];
  while (item && item.parentId !== null) {
    path.unshift({ id: item.id, name: item.name });

    findNode(rootNode, item.parentId, getNodeItem);
  }
  item && path.unshift({ id: item.id, name: item.name });

  return getPathString ? path.map((node) => node.name).join(" >> ") : path;
};

// Tree Animation
export const treeMotion = {
  motionAppear: false,
  motionName: "node-motion",
  onEnterActive: (node) => ({ height: node.scrollHeight }),
  onLeaveStart: (node) => ({ height: node.offsetHeight }),
};

// Default Tree
export const getDefaultTree = () => {
  return { treeData: [{ id: uniqueID(), name: "Home", isLeaf: true, parentId: null, children: [] }] };
};

// filter right answers becoz user may have update the tree and current node may not be the leaf node.
export const filterAnswers = (treeData, answers) => {
  answers = answers.filter((nodeId) => {
    let item;
    findNode(treeData[0], nodeId, (node) => (item = node));
    if (item && item.children && item.children.length === 0) return nodeId;
    return "";
  });
  return answers;
};

// Convert JSON to TreeData
export const jsonToTreeData = (jsonArray) => {
  const data = {};
  jsonArray.forEach((item) => {
    let node = { id: uniqueID(), name: "", isLeaf: true, parentId: null, children: [] };
    item.forEach((childItem, index) => {
      childItem = childItem.toString().trim();
      if (childItem) {
        let indexData = data[index] || [];
        node.name = childItem;

        // Get Parent [Last index]
        let parentData = data[index - 1];
        if (parentData) {
          let parent = parentData[parentData.length - 1];
          node.parentId = parent.id;
          parent.isLeaf = false;
          parent.children.push(node);
        }
        indexData.push(node);

        data[index] = indexData;
      }
    });
  });

  return data[0];
};

// Export the tree
export const exportTree = ({ name, tree }) => {
  return new Promise((resolve, reject) => {
    try {
      const wb = XLSX.utils.book_new();
      const treeInfo = getTreeArray(tree.treeData);

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(treeInfo), "Tree Details");
      XLSX.writeFile(wb, name + ".xlsx");

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
