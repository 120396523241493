import { REPORT_LOAD, REPORT_RESET, REPORT_FILTER, REPORT_FILTER_SET, REPORT_LOADING, TEST } from "../actions/types";
import Bowser from "bowser";

const filters = {
  enable: false,
  applied: false,
  countries: [],
  genders: [],
  devices: [],
  deviceTypes: [],
  ageGroups: [],
  ethnicities: [],
};
const initialState = { filters, test: null, allResponses: [], response: [], reportResponses: [], loading: false, error: null };

const reportReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_LOAD:
      return { ...state, ...payload, filters, loading: false };
    case REPORT_RESET:
      return { ...state, ...initialState };
    case REPORT_LOADING:
      return { ...state, payload };
    case REPORT_FILTER_SET:
      return { ...state, filters: payload ? payload : filters };
    case REPORT_FILTER:
      const response = state.allResponses.reduce((result, res) => {
        const { nationality, gender, userAgent, others } = res.participant;
        const { countries, genders, devices, deviceTypes, ageGroups, ethnicities } = payload ? payload : filters;

        if (countries.length > 0 && (!nationality || !countries.includes(nationality))) return result;
        if (genders.length > 0 && (gender === undefined || !genders.includes(gender))) return result;
        if (ageGroups.length > 0 && (!others || !others.ageGroup || !ageGroups.includes(others.ageGroup))) return result;
        if (ethnicities.length > 0 && (!others || !others.ethnicity || !ethnicities.includes(others.ethnicity))) return result;
        if ((devices.length > 0 || deviceTypes.length > 0) && !userAgent) return result;
        if (userAgent) {
          const { os, platform } = Bowser.parse(userAgent);
          if (devices.length > 0 && !devices.includes(os.name)) return result;
          if (deviceTypes.length > 0 && !deviceTypes.includes(platform.type)) return result;
        }

        return [...result, res];
      }, []);

      return { ...state, response };

    case TEST.UPDATED:
      return { ...state, test: payload };
    default:
      return state;
  }
};

export default reportReducer;
