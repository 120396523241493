import axios from "../utills/axios";
import { TEST, REPORT_LOAD, REPORT_LOADING } from "./types";
import { createDesiredResponse } from "../utills/reportFunctions";

export const saveCategory = (item, test) => async (dispatch) => {
  if (item && test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const { categories } = test.card_sort;

      // Update Category
      if (item._id) {
        const result = await axios.put(`/api/category/${item._id}`, item);
        const index = categories.findIndex((category) => category._id === item._id);
        categories.splice(index, 1, result.data);
      } else {
        const result = await axios.post(`/api/test/${test._id}/category`, item);
        categories.push(result.data);
      }

      test.card_sort = { ...test.card_sort, categories };
      dispatch({ type: TEST.UPDATED, payload: test });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const createMultipleCategories = (item, test) => async (dispatch) => {
  if (item && test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const result = await axios.post(`/api/test/${test._id}/multipleCategories`, item);
      const { card_sort } = result.data;
      dispatch({ type: TEST.UPDATED, payload: { ...test, card_sort } });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteCategory = (categoryId, test) => async (dispatch) => {
  if (categoryId && test) {
    dispatch({ type: TEST.SAVING, payload: true });
    try {
      await axios.delete(`/api/test/${test._id}/category/${categoryId}`);

      const categories = test.card_sort.categories.filter((cat) => cat._id !== categoryId);
      test.card_sort = { ...test.card_sort, categories };
      dispatch({ type: TEST.UPDATED, payload: test });
    } catch (error) {
      console.log(error);
    }
  }
};

export const saveCategoryImage = (item, image, test) => async (dispatch) => {
  if (item && test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const { categories } = test.card_sort;
      const result = await axios.post(`/api/category/${item._id}/image`, image);
      const index = categories.findIndex((category) => category._id === item._id);
      categories.splice(index, 1, result.data);

      test.card_sort = { ...test.card_sort, categories };
      dispatch({ type: TEST.UPDATED, payload: test });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteCategoryImage = (item, test) => async (dispatch) => {
  if (item && test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      const { categories } = test.card_sort;
      const imageId = item.image[0]._id;
      const result = await axios.delete(`/api/category/${item._id}/image/${imageId}`);
      const index = categories.findIndex((category) => category._id === item._id);
      categories.splice(index, 1, result.data);

      test.card_sort = { ...test.card_sort, categories };
      dispatch({ type: TEST.UPDATED, payload: test });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteAllCategories = (test) => async (dispatch) => {
  if (test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      await axios.delete(`/api/test/${test._id}/allCategory`);
      dispatch({ type: TEST.UPDATED, payload: { ...test, card_sort: { ...test.card_sort, categories: [] } } });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const saveCategorySorting = (categories, test) => async (dispatch) => {
  if (categories && test) {
    dispatch({ type: TEST.SAVING, payload: true });

    try {
      await axios.post("/api/category/updateMany", categories);
      test.card_sort = { ...test.card_sort, categories };
      dispatch({ type: TEST.UPDATED, payload: test });
      return true;
    } catch (error) {
      console.log(error);
    }
  }
};

export const mergeCategories = (testId, data) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_LOADING, payload: true });
    const res = await axios.post(`/api/test/${testId}/merge_categories`, data);
    let { test, response } = res.data;
    dispatch({ type: REPORT_LOAD, payload: { test, ...createDesiredResponse(response) } });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const unmergeCategories = (testId, categoryId) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_LOADING, payload: true });
    const res = await axios.post(`/api/test/${testId}/unmerge_category/${categoryId}`);
    let { test, response } = res.data;
    dispatch({ type: REPORT_LOAD, payload: { test, ...createDesiredResponse(response) } });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Axios call
const axiosCall = (url, data, method = "post") => {
  return new Promise((resolve, reject) => {
    axios[method](url, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Add/Image Category Image
export const categoryActions = {
  saveCategory: (item, testId) => {
    if (item._id) return axiosCall(`/api/category/${item._id}`, item, "put"); // update category
    else return axiosCall(`/api/test/${testId}/category`, item); // create category
  },
  saveCategoryImage: (itemId, image) => axiosCall(`/api/category/${itemId}/image`, image),
  saveCategorySorting: (categories = [], testId) => axiosCall(`/api/category/updateMany/${testId}`, categories),
  createMultipleCategories: (items, testId) => axiosCall(`/api/test/${testId}/multipleCategories`, items),
  deleteAllCategories: (testId) => axiosCall(`/api/test/${testId}/allCategory`, null, "delete"),
  deleteCategory: (itemId, testId) => axiosCall(`/api/test/${testId}/category/${itemId}`, null, "delete"),
  deleteCategoryImage: (itemId, imageId) => axiosCall(`/api/category/${itemId}/image/${imageId}`, null, "delete"),
};
