import Router from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { App as AntApp, ConfigProvider } from "antd";
import { PersistGate } from "redux-persist/integration/react";
import Notification from "./components/layouts/Notification";

import UXLoader from "./components/layouts/UXLoader";
import AuthProvider from "./components/auth/AuthProvider";

export default function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={<UXLoader useFullHeight />} persistor={persistor}>
          <ConfigProvider
            theme={{
              hashed: false,
              token: {
                borderRadius: 6,
                controlHeight: 36,
                // colorInfo: "#2cbfca", // use it for the Typography.Link color
                colorPrimary: "#2cbfca",
                fontFamily: "Roboto, sans-serif",
              },
            }}
          >
            <AntApp>
              <AuthProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </AuthProvider>

              <Notification />
            </AntApp>
          </ConfigProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}
