// Test Status
export const TEST_STATUS_DRAFT = 0;
export const TEST_STATUS_ACTIVE = 1;
export const TEST_STATUS_INACTIVE = 2;
export const TEST_STATUS_EDIT = 3;
export const TEST_STATUS_COMPLETED = 4;
export const TEST_STATUS_DELETED = 5;

// Response
export const RESULT_SUCCESS = 1;
export const RESULT_FAILED = 0;
export const RESULT_SCREENOUT = 2;
export const RESULT_DIRECT_SUCCESS = 1;
export const RESULT_INDIRECT_SUCCESS = 2;
export const RESULT_DIRECT_FAILURE = 3;
export const RESULT_INDIRECT_FAILURE = 4;
export const RESULT_DIRECT_SKIP = 5;
export const RESULT_INDIRECT_SKIP = 6;

// Test Types
export const TEST_TYPE_TT = 0;
export const TEST_TYPE_PD = 1;
export const TEST_TYPE_CS = 2;

// Test Device Types
export const TEST_DEVICE_TYPE_DESKTOP = 0;
export const TEST_DEVICE_TYPE_SMARTPHONE = 1;

// Task Markers
export const MAX_TASK_MARKERS = 26;
export const MARKER_OFFSET_SIZE = 18;

// User Gender
export const GENDER_UNKNOWN = -1;
export const GENDER_FEMALE = 0;
export const GENDER_MALE = 1;

// Product names
export const PRODUCT_TREE = "tree-testing";
export const PRODUCT_PARTICIPATORY = "participatory-design";
export const PRODUCT_CARD_SORTING = "card-sorting";

// PLANS
export const PLAN_FREE = 0;
export const PLAN_MONTHLY = 1;
export const PLAN_YEARLY = 2;
export const PLAN_ENTERPRISES = 3;
export const PLAN_MONTHLY_TEXT = "monthly";
export const PLAN_YEARLY_TEXT = "annually";

// Subscription
export const SUBSCRIPTION_ACTIVE = 1;

// TREE Direction By TESTER
export const DIRECTION_SIBLING = "sibling";
export const DIRECTION_FORWARD = "forward";
export const DIRECTION_BACKWARD = "backward";

// Card Types
export const CARD_TYPE_OPEN = 0;
export const CARD_TYPE_CLOSE = 1;
export const CARD_TYPE_HYBRID = 2;

// REQUIRE EXPLANATION
export const REQUIRE_EXPLANATION_NO = 0;
export const REQUIRE_EXPLANATION_YES = 1;
export const REQUIRE_EXPLANATION_OPTIONAL = 2;

// Image Position
export const POSITION_LEFT = 0;
export const POSITION_RIGHT = 1;

export const MIN_CARDS = 2;
export const MIN_CATEGORIES = 2;

export const MERGED_CATEGORY = 3;
export const UNCLEAR_CATEGORY = 2;
export const TESTER_CATEGORY = 1;
export const CLIENT_CATEGORY = 0;

export const CREATE_CATEGORY = 1;
export const UPDATE_CATEGORY = 2;
export const DELETE_CATEGORY = 3;

export const MAX_LABEL_CHARS = 100;
export const MAX_DESC_CHARS = 255;
export const RESPONSES = { DEAFULT: 60, MAX_COMPLETED: 250 };

// Survey
export const SURVEY_TYPE_SCREENER = "screener";
export const SURVEY_TYPE_PRE = "pre";
export const SURVEY_TYPE_POST = "post";

export const QUEST_TYPE_TEXT = 0;
export const QUEST_TYPE_CHECKBOX = 1;
export const QUEST_TYPE_RADIO = 2;
export const QUEST_TYPE_RATING = 3;

export const OPTION_TYPE_TEXT = 0;
export const OPTION_TYPE_OTHERS = 1;

// ADMIN
export const IS_ADMIN_NO = 0;
export const IS_ADMIN_YES = 1;

// Include/Exclude Participants
export const INCLUDE_PARTICIPANT = { YES: true, NO: false };
