import PropTypes from "prop-types";
import TopNavbar from "./TopNavbar";
import { RUI_ICON } from "../../utills/icons";
import { Image, Typography, Button } from "antd";

const { Title, Paragraph } = Typography;

export default function MobleView({ title = "", content = "" }) {
  return (
    <>
      <TopNavbar showLogoOnly />
      <div className='text-center pt-5 p-2'>
        <Image src={RUI_ICON} preview={false} />

        {title && <Title level={2}>{title}</Title>}

        <Paragraph className='montserrat mt-2 font-500'>{content || "Please switch to a Computer for using UXArmy tools"}</Paragraph>

        <Button href={process.env.REACT_APP_UXARMY_URL}>Browse UX Toolkit</Button>
      </div>
    </>
  );
}

MobleView.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};
