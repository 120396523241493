import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import TestProvider from "./TestProvider";
import TopNavbar from "../layouts/TopNavbar";

const { Content } = Layout;

export default function TestLayout() {
  return (
    <Layout className='h-100vh test-creation'>
      <Content className='bg-white h-100'>
        <TopNavbar />

        <TestProvider>
          <Outlet />
        </TestProvider>
      </Content>
    </Layout>
  );
}
