import { Layout, Menu } from "antd";
import { getProductName } from "../../utills/common";
import { useAuthContext } from "../../components/auth/AuthProvider";
import { Outlet, Navigate, useNavigate, useLocation } from "react-router-dom";
import { PRODUCT_TREE, PRODUCT_CARD_SORTING, PRODUCT_PARTICIPATORY } from "../../actions/constants";
import CustomIcon from "../../components/layouts/CustomIcon";
import UserAvatar from "../../components/layouts/UserAvatar";
import ProductsMenu from "../../components/layouts/ProductsMenu";

const { Sider, Content } = Layout;

export default function DashboardLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname.split("/")[1];

  const { logout } = useAuthContext();

  if (currentPath === PRODUCT_PARTICIPATORY) return <Navigate to='/' />;

  const menuLinks = [
    { key: "", label: "All Tests", icon: "test-details" },
    { key: PRODUCT_CARD_SORTING, label: getProductName(PRODUCT_CARD_SORTING), icon: "cardsorting" },
    { key: PRODUCT_TREE, label: getProductName(PRODUCT_TREE), icon: "treetesting" },
    // { key: PRODUCT_PARTICIPATORY, label: getProductName(PRODUCT_PARTICIPATORY), icon: "participatory" },
    { key: "divider1", isDivider: true },
    { key: "settings", label: "Settings", icon: "gear" },
    { key: "subscriptions", label: "My Subscriptions", icon: "iterative" },
    { key: "logout", label: "Logout", icon: "logout" },
  ];

  const menuItems = menuLinks.map(({ key, label, icon, isDivider }) => {
    if (isDivider) return { key, type: "divider" };

    if (key === "logout") {
      return {
        key,
        label,
        onClick: logout,
        className: "logout-btn d-flex align-items-center font-500",
        icon: <CustomIcon name='logout' style={{ height: 22, width: 22 }} />,
      };
    }

    return {
      label,
      key: key || "recent_tests",
      onClick: () => navigate(`/${key}`),
      className: "m-0 d-flex align-items-center",
      icon: (
        <CustomIcon name={icon} style={{ height: 40, width: 40, display: "flex", alignItems: "center", justifyContent: "center" }} />
      ),
    };
  });

  return (
    <Layout className='h-100vh dashboard'>
      <Sider width={280} className='bg-white sidebar'>
        <ProductsMenu product={currentPath === PRODUCT_TREE ? "treetesting" : "cardsorting"} />
        <UserAvatar />
        <Menu items={menuItems} selectedKeys={[currentPath || "recent_tests"]} theme='light' />
      </Sider>

      <Content className='bg-white h-100'>
        <Outlet />
      </Content>
    </Layout>
  );
}
