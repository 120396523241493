import TopNavbar from "./TopNavbar";
import { useTranslation } from "react-i18next";
import { Button, Image, Typography } from "antd";
import { OH_SNAP_ICON } from "../../utills/icons";

const { Title } = Typography;

export default function UXMessage({ message, showTopNavbar = true }) {
  const { t } = useTranslation();

  return (
    <>
      {showTopNavbar && <TopNavbar />}

      <div className='flex-item flex-center' style={{ height: "calc(90vh - 5em)" }}>
        <div className='flex-item flex-center flex-column'>
          <Image src={OH_SNAP_ICON} preview={false} />
          <Title level={3} className='mt-2 mb-4 text-gray-19'>
            {message}
          </Title>
          <Button href='/' type='primary'>
            {t("RETUN_TO_HOME")}
          </Button>
        </div>
      </div>
    </>
  );
}
