import PropTypes from "prop-types";
import { connect } from "react-redux";
import UXLoader from "../layouts/UXLoader";
import { TEST_ITEMS_KEYS } from "../../actions/types";
import { useAuthContext } from "../auth/AuthProvider";
import { useParams, Navigate } from "react-router-dom";
import { surveyTypes } from "../../utills/testFunctions";
import { saveTask, deleteTask } from "../../actions/task";
import { updateTestTree, deleteTestTree } from "../../actions/tree";
import { useMemo, useEffect, useContext, createContext, useState, useCallback } from "react";
import { saveTestState, getTest, saveTest, launchTest, deactivateTest, testSaving } from "../../actions/test";
import {
  TEST_TYPE_CS,
  TEST_TYPE_PD,
  TEST_TYPE_TT,
  TEST_STATUS_ACTIVE,
  TEST_STATUS_INACTIVE,
  TEST_STATUS_COMPLETED,
  PRODUCT_TREE,
  PRODUCT_CARD_SORTING,
  PRODUCT_PARTICIPATORY,
} from "../../actions/constants";

// TestContext to keep the latest state
const TestContext = createContext(null);

// useTestContext to share this components state everywhere this component is imported
export const useTestContext = () => {
  const context = useContext(TestContext);

  if (!context) throw new Error("useTestContext context must be use inside TestProvider");

  return context;
};

const TestProvider = ({
  getTest,
  children,
  saveTask,
  saveTest,
  testState,
  launchTest,
  testSaving,
  deleteTask,
  saveTestState,
  deactivateTest,
  updateTestTree,
  deleteTestTree,
}) => {
  const { testId: testIdFromParams, action = "" } = useParams();
  const { isLogoExists, isPlanExpiredForAll } = useAuthContext();
  const { loading, isSaving, isSavingTask, duplicateTestId } = testState;

  const [showErrors, setShowErrors] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(TEST_ITEMS_KEYS.DETAILS);

  // Initial Test Object
  const test = useMemo(() => {
    const testObj = testState?.test || {};

    // Disable Logo if doesn't exist
    if (!isLogoExists && testObj?.settings) testObj.settings = { ...testObj.settings, include_logo: false };

    // Recruit Link
    if (testObj?._id) testObj.recruitLink = `${window.location.origin}/study/${testObj._id}`;

    return testObj;
  }, [testState, isLogoExists]);

  const testId = test?._id;
  const isEdit = action === "edit";
  const isNew = testIdFromParams === "new";
  const testIdStr = !isNew ? testIdFromParams : null;
  const isPreview = action === "preview" || [TEST_STATUS_ACTIVE, TEST_STATUS_INACTIVE, TEST_STATUS_COMPLETED].includes(test?.status);

  // Test Type
  if (!test.type && !isPreview && action && [PRODUCT_TREE, PRODUCT_CARD_SORTING, PRODUCT_PARTICIPATORY].includes(action)) {
    if (action === PRODUCT_TREE) test.type = TEST_TYPE_TT;
    else if (action === PRODUCT_CARD_SORTING) test.type = TEST_TYPE_CS;
    else if (action === PRODUCT_PARTICIPATORY) test.type = TEST_TYPE_PD;
  }

  // TEST TYPES
  const isTreeTest = test?.type === TEST_TYPE_TT;
  const isCardTest = test?.type === TEST_TYPE_CS;
  const isParticipateTest = test?.type === TEST_TYPE_PD;

  // Tasks
  const tasks = useMemo(() => test?.tasks || [], [test]);

  // Recruit Link
  const getRecruitLink = useCallback(() => `${window.location.origin}/study/${testId}`, [testId]);

  // Check if Survey is included
  const isSurveyIncluded = useCallback((key, survey) => survey[key] && survey[key].include, []);

  // Enable Questionnaire
  const enableQuestionanire = useMemo(
    () =>
      surveyTypes().reduce(
        (enable, item) =>
          enable ? enable : test && test.settings && test.settings.survey && isSurveyIncluded(item.key, test.settings.survey),
        false
      ),
    [test, isSurveyIncluded]
  );

  // Get the Test
  useEffect(() => {
    if (testIdStr) getTest(testIdStr, isEdit);

    // Reset State
    return () => saveTestState(null);
  }, [getTest, testIdStr, isEdit, saveTestState]);

  const memoizedValue = useMemo(
    () => ({
      test,
      tasks,
      isEdit,
      testId,
      loading,
      isSaving,
      isPreview,
      isTreeTest,
      isCardTest,
      showErrors,
      setShowErrors,
      activeTabKey,
      isSavingTask,
      saveTest,
      launchTest,
      testSaving,
      saveTestState,
      deactivateTest,
      duplicateTestId,
      setActiveTabKey,
      isParticipateTest,
      isSurveyIncluded,
      enableQuestionanire,
      saveTask,
      deleteTask,
      getRecruitLink,
      updateTestTree,
      deleteTestTree,
    }),
    [
      test,
      tasks,
      isEdit,
      testId,
      loading,
      isSaving,
      isPreview,
      isTreeTest,
      isCardTest,
      showErrors,
      setShowErrors,
      activeTabKey,
      isSavingTask,
      saveTest,
      launchTest,
      testSaving,
      saveTestState,
      deactivateTest,
      duplicateTestId,
      setActiveTabKey,
      isParticipateTest,
      isSurveyIncluded,
      enableQuestionanire,
      saveTask,
      deleteTask,
      getRecruitLink,
      updateTestTree,
      deleteTestTree,
    ]
  );

  if (loading) return <UXLoader />;
  if (isPlanExpiredForAll && !testId && !loading) return <Navigate to='/?planExpired=1' />;

  // Redirect to actual test page on create
  if (isNew && testId) return <Navigate to={`/tests/${testId}/${action}`} replace={true} />;

  return <TestContext.Provider value={memoizedValue}>{children}</TestContext.Provider>;
};

const mapStateToProps = ({ test }) => ({ testState: test });

export default connect(mapStateToProps, {
  getTest,
  saveTest,
  saveTask,
  testSaving,
  deleteTask,
  launchTest,
  saveTestState,
  deactivateTest,
  updateTestTree,
  deleteTestTree,
})(TestProvider);

TestProvider.propTypes = {
  testState: PropTypes.object,
  children: PropTypes.node.isRequired,
  saveTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  testSaving: PropTypes.func.isRequired,
  getTest: PropTypes.func.isRequired,
  saveTest: PropTypes.func.isRequired,
  launchTest: PropTypes.func.isRequired,
  saveTestState: PropTypes.func.isRequired,
  deactivateTest: PropTypes.func.isRequired,
  updateTestTree: PropTypes.func.isRequired,
  deleteTestTree: PropTypes.func.isRequired,
};
