import axios from "axios";
import { redirectToYii } from "./common";

// BASE_URL of Server
axios.defaults.withCredentials = true;
axios.defaults.headers["Pragma"] = "no-cache";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

// Add a request interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response && error?.response?.status === 401) return redirectToYii();
    return Promise.reject(error);
  }
);

export default axios;
