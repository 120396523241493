import { Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function UXLoader({ text = "", useFullHeight = false }) {
  return (
    <div className={`flex-item flex-center ${useFullHeight ? "h-100vh" : "h-100"}`}>
      <div className='flex-item flex-column'>
        <LoadingOutlined className='font-size-24px' />
        <Typography.Paragraph className='roboto m-0 mt-2'>{text || "Please wait..."}</Typography.Paragraph>
      </div>
    </div>
  );
}
