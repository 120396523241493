import { STUDY } from "../actions/types";
import { shuffleArray } from "../utills/common";
import { TEST_TYPE_TT, TEST_TYPE_CS, CARD_TYPE_OPEN, UNCLEAR_CATEGORY } from "../actions/constants";

const defaultTestData = {
  taskIndex: 0,
  taskAnswer: {},
  isTaskStarted: false,
  showGuideModal: true,
  showConfirmationModal: false,
  cardSort: {
    cards: [],
    feedback: "",
    categories: [],
    showInstruction: true,
    showConfirmation: false,
    userResponse: { cards: {}, categories: {} },
  },
};

const initialState = {
  test: null,
  error: null,
  overQuotaUrl: "",
  questionIndex: 0,
  participant: null,
  isPreview: false,
  isSavingTask: false,
  testSurveyAnswers: {},
  testData: defaultTestData,
  branding: { logo: null, color: "" },
};

const studyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STUDY.LOAD:
      return { ...state, ...payload };
    case STUDY.TASK_SAVING:
      return { ...state, isSavingTask: payload };
    case STUDY.COMPLETE:
      return initialState;
    case STUDY.UPDATE:
      if (payload.isNew) {
        const { test, testData } = state;

        if (test.type === TEST_TYPE_CS) {
          let { cards, categories } = test.card_sort;
          const { card, category } = test.settings.card_sort;

          if (test.settings.card_sort.type === CARD_TYPE_OPEN) categories = [];
          else if (category.shuffle) categories = shuffleArray(categories);
          if (card.shuffle) cards = shuffleArray(cards);

          if (category.unclear) {
            const unclearCategory = categories.find((cat) => cat.type === UNCLEAR_CATEGORY);
            const newCategories = categories.filter((cat) => cat.type !== UNCLEAR_CATEGORY);
            if (unclearCategory) categories = [unclearCategory, ...newCategories].map((cat) => ({ ...cat, catCards: [] }));
          }

          const newTestData = { ...testData, cardSort: { ...testData.cardSort, showInstruction: true, cards, categories } };

          return { ...state, test, testData: newTestData };
        } else {
          const showGuideModal = payload.showGuideModal ? payload.showGuideModal : state.testData.showGuideModal;
          const taskIndex = payload.taskIndex ? payload.taskIndex : state.testData.taskIndex;
          const taskAnswer = {
            id: test.tasks[taskIndex]._id,
            startTime: new Date().getTime(),
            answer: test.type === TEST_TYPE_TT ? [{ id: test.tree.treeData[0].id, label: test.tree.treeData[0].name }] : [],
          };
          const testData = { ...state.testData, taskIndex, taskAnswer, showGuideModal, isTaskStarted: false };
          return { ...state, testData };
        }
      }

      return { ...state, testData: payload };
    case STUDY.SURVEY_LOAD:
      const { testSurveyAnswers } = state;
      if (!testSurveyAnswers[payload]) testSurveyAnswers[payload] = [];
      return { ...state, questionIndex: 0, testSurveyAnswers };
    case STUDY.SURVEY_SAVE:
      return { ...state, testSurveyAnswers: payload };
    case STUDY.MOVE_PREV:
      return { ...state, questionIndex: state.questionIndex - 1, isSavingTask: false };
    case STUDY.MOVE_NEXT:
      return { ...state, questionIndex: state.questionIndex + 1, isSavingTask: false };
    case STUDY.ATTEMPTED:
      const { test } = state;
      let comet = JSON.parse(localStorage.getItem("comet")) || [];
      if (comet.length === 0 || (comet.length && comet.indexOf(test._id) === -1)) {
        comet.push(test._id);
        localStorage.setItem("comet", JSON.stringify(comet));
      }
      return { ...state };

    default:
      return state;
  }
};

export default studyReducer;
