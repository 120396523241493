import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import StudyProvider from "./StudyProvider";
import PreviewText from "./layouts/PreviewText";

const { Content } = Layout;

export default function StudyLayout() {
  return (
    <Layout className='h-100vh test-study'>
      <Content className='bg-white h-100'>
        <StudyProvider>
          <PreviewText />

          <Outlet />
        </StudyProvider>
      </Content>
    </Layout>
  );
}
