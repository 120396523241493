import { LOAD_PLANS, LOAD_PLAN_PREVIEW, ERROR_PLAN_PREVIEW } from "../actions/types";

const initialState = { plans: null, upgradePreview: null, errors: null };

const checkoutReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_PLANS:
      return { ...state, plans: payload };
    case LOAD_PLAN_PREVIEW:
      return { ...state, upgradePreview: payload, errors: null };
    case ERROR_PLAN_PREVIEW:
      return { ...state, errors: payload, upgradePreview: null };
    default:
      return state;
  }
};

export default checkoutReducer;
