import { SET_MESSAGE, REMOVE_MESSAGE } from '../actions/types';

const initialState = null;

const messageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE:
      return payload;
    case REMOVE_MESSAGE:
      return null;
    default:
      return state;
  }
};
export default messageReducer;
