import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { logout, updateUserState, loadUser } from "../../actions/auth";
import { createContext, useEffect, useMemo, useContext, useCallback } from "react";
import { isPlanExpired, isFreeTrial, getPlanExpireTime } from "../../utills/common";
import CreateAccount from "../layouts/CreateAccount";

// AuthContext to keep the latest state
const AuthContext = createContext(null);

// useAuthContext to share this components state everywhere this component is imported
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("useAuthContext context must be use inside AuthProvider");

  return context;
};

const AuthProvider = ({ auth, logout, loadUser, updateUserState, children }) => {
  const { user, error, loading, isAuthenticated } = auth;

  // Full Name
  if (user) user.fullName = user.name ? user.name.first + (user.name.last ? " " + user.name.last : "") : "";

  const branding = useMemo(() => (user && user.account ? user.account.branding : {}), [user]);
  const subscriptions = useMemo(() => (user && user.account ? user.account.subscriptions : []), [user]);
  const isLogoExists = useMemo(() => user && user.account && user.account.branding && user.account.branding.logo.length > 0, [user]);

  useIdleTimer({
    name: "UX",
    crossTab: true,
    syncTimers: 500,
    startOnMount: true,
    eventsThrottle: 200,
    leaderElection: true,
    timeout: 1000 * 60 * 2, // user will considered Idle in case of user in-activity for two minutes.
    onPresenceChange: (presence) => {
      if (presence?.type === "active" && !window?.location?.pathname.startsWith("/study")) loadUser();
    },
  });

  // Get the User
  useEffect(() => {
    // Load user if not on study page
    if (!window?.location?.pathname.startsWith("/study")) loadUser();
  }, [loadUser]);

  // Plan expired for all products
  const isPlanExpiredForAll = useMemo(() => isPlanExpired(subscriptions), [subscriptions]);

  // Is free trial for a product
  const isFreeTrialForProduct = useCallback((product = "") => isFreeTrial(subscriptions, product), [subscriptions]);

  // Is plan expired for a product
  const isPlanExpiredForProduct = useCallback((product = "") => isPlanExpired(subscriptions, product), [subscriptions]);

  // Get plan expire time for a product
  const getPlanExpireTimeForProduct = useCallback((product = "") => getPlanExpireTime(subscriptions, product), [subscriptions]);

  const memoizedValue = useMemo(
    () => ({
      user,
      error,
      logout,
      loading,
      branding,
      isLogoExists,
      subscriptions,
      updateUserState,
      isAuthenticated,
      isPlanExpiredForAll,
      isFreeTrialForProduct,
      isPlanExpiredForProduct,
      getPlanExpireTimeForProduct,
    }),
    [
      user,
      error,
      logout,
      loading,
      branding,
      isLogoExists,
      subscriptions,
      updateUserState,
      isAuthenticated,
      isPlanExpiredForAll,
      isFreeTrialForProduct,
      isPlanExpiredForProduct,
      getPlanExpireTimeForProduct,
    ]
  );

  if (error && error?.noAccount) return <CreateAccount />;

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

const mapStateToProps = ({ auth }) => ({ auth });
AuthProvider.propTypes = {
  auth: PropTypes.object,
  logout: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  updateUserState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { logout, loadUser, updateUserState })(AuthProvider);
