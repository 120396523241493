import { uniqueID } from "../utills/common";
import { SURVEY_LOAD, SAVE_SURVEY_STATE, SURVEY_ADD_QUESTION } from "../actions/types";
import { OPTION_TYPE_TEXT, QUEST_TYPE_RADIO, QUEST_TYPE_TEXT, SURVEY_TYPE_SCREENER } from "../actions/constants";

const initialState = { questions: [], activeId: null };
const newQuestion = (position = 0, question_type = QUEST_TYPE_TEXT) => ({
  position,
  label: "",
  active: true,
  question_type,
  id: uniqueID(),
  option: [{ id: uniqueID(), label: "", type: OPTION_TYPE_TEXT, position: 0 }],
});

const surveyReducer = (state = initialState, action) => {
  const { questions } = state;
  const { type, payload } = action;

  switch (type) {
    case SURVEY_LOAD:
      return { ...state, questions: payload };
    case SAVE_SURVEY_STATE:
      return { ...state, ...payload };
    case SURVEY_ADD_QUESTION:
      const question_type = payload === SURVEY_TYPE_SCREENER ? QUEST_TYPE_RADIO : QUEST_TYPE_TEXT;
      const preQuestions = questions.map((q) => ({ ...q, active: false }));
      return { ...state, questions: [...preQuestions, newQuestion(preQuestions.length, question_type)] };
    default:
      return state;
  }
};

export default surveyReducer;
