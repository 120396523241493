import { useState } from "react";
import { Modal, Typography } from "antd";
import { createAccount } from "../../actions/auth";
import { redirectToYii } from "../../utills/common";

const { Title, Paragraph } = Typography;

export default function CreateAccount() {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const onStartFreeTrial = async () => {
    try {
      setIsSubscribing(true);
      await createAccount();

      // Redirect to dashboard
      window.location.reload();
    } catch (err) {
      console.log(err.message);
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <Modal
      centered
      open={true}
      keyboard={false}
      title='Free trial'
      maskClosable={false}
      onOk={onStartFreeTrial}
      okText='Start Free trial'
      cancelText='Cancel, Go back'
      confirmLoading={isSubscribing}
      onCancel={() => redirectToYii("v2/products", false)}
    >
      <Title level={4}>No active subscription</Title>
      <Paragraph>This will start the 7-day Free trial of Card Sort and Tree Test.</Paragraph>
    </Modal>
  );
}
