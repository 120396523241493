import axios from "../utills/axios";
import { TEST, AUTH_ERROR } from "./types";
import {
  TEST_TYPE_TT,
  TEST_TYPE_CS,
  TEST_TYPE_PD,
  UNCLEAR_CATEGORY,
  TEST_STATUS_EDIT,
  TEST_STATUS_DRAFT,
  TEST_STATUS_ACTIVE,
  TEST_STATUS_DELETED,
  TEST_STATUS_INACTIVE,
  PRODUCT_CARD_SORTING,
  PRODUCT_PARTICIPATORY,
} from "./constants";
import { setAlert } from "./alert";
import { getBlankCatCard } from "../utills/common";

// Get All Tests
export const getAllTests = (menuType) => async (dispatch) => {
  try {
    dispatch({ type: TEST.LOADING, payload: true });
    const res = await axios.get("/api/account/tests");

    let { tests } = res.data;

    if (menuType) {
      let filterBy = TEST_TYPE_TT;
      if (menuType === PRODUCT_PARTICIPATORY) filterBy = TEST_TYPE_PD;
      else if (menuType === PRODUCT_CARD_SORTING) filterBy = TEST_TYPE_CS;

      tests = tests.filter((test) => test.type === filterBy);
    }

    dispatch({ type: TEST.LOADED, payload: tests });
  } catch (error) {
    if (error.response && error.response.status && error.response.status === 401) dispatch({ type: AUTH_ERROR });
    else dispatch({ type: TEST.LOADED, payload: [] });
  }
};

// Get Test
export const getTest = (testId, isEdit) => async (dispatch) => {
  if (!testId) return;

  dispatch({ type: TEST.LOADING, payload: true });

  try {
    const { data } = await axios.get(`/api/test/${testId}`);

    // Sort Cards/Categories
    const { card_sort } = data;
    const categories = card_sort.categories.filter((cat) => cat?.type !== UNCLEAR_CATEGORY);
    const test = {
      ...data,
      card_sort: {
        ...card_sort,
        cards: card_sort.cards.length > 0 ? card_sort.cards.sort((a, b) => a.position - b.position) : [getBlankCatCard()],
        categories: categories.length > 0 ? categories.sort((a, b) => a?.position - b?.position) : [getBlankCatCard()],
      },
    };

    dispatch({ type: TEST.UPDATED, payload: test });

    if (isEdit && !test.isEditable) dispatch(setAlert("Test is no longer available for Editing."));
    else if (isEdit && test.isEditable && test.status !== TEST_STATUS_EDIT && test.status !== TEST_STATUS_DRAFT) {
      dispatch(editTest(test));
    }
  } catch (error) {
    error.response && error.response.data && dispatch(setAlert(error.response.data.msg, "error"));
  } finally {
    dispatch({ type: TEST.LOADING, payload: false });
  }
};

// Save Test State
export const saveTestState = (test) => (dispatch) => dispatch({ type: TEST.SAVE_STATE, payload: test });

// Save Test
export const saveTest = (test, setCancelToken) => async (dispatch) => {
  dispatch({ type: TEST.SAVING, payload: true });

  try {
    const requestParams = {};

    if (setCancelToken) {
      // Create a new cancel token
      const source = axios.CancelToken.source();
      requestParams.cancelToken = source.token;

      setCancelToken(source);
    }

    if (test._id) {
      const res = await axios.put(`/api/test/${test._id}`, test, requestParams);
      dispatch({ type: TEST.UPDATED, payload: { ...test, messages: res.data.messages } });
    } else {
      const res = await axios.post("/api/test", test, requestParams);
      dispatch({ type: TEST.CREATED, payload: res.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: TEST.SAVING, payload: false });
  }
};

// Launch Test
export const launchTest = (test) => async (dispatch) => {
  dispatch({ type: TEST.SAVING, payload: true });

  try {
    await axios.put(`/api/test/${test._id}/status`, JSON.stringify({ status: TEST_STATUS_ACTIVE }));

    test.status = TEST_STATUS_ACTIVE;
    dispatch({ type: TEST.UPDATED, payload: test });
    dispatch(setAlert("Test has been launched successfully."));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Un-Launch Test
export const deactivateTest = (test) => async (dispatch) => {
  dispatch({ type: TEST.SAVING, payload: true });

  try {
    await axios.put(`/api/test/${test._id}/status`, JSON.stringify({ status: TEST_STATUS_INACTIVE }));

    test.status = TEST_STATUS_INACTIVE;
    dispatch({ type: TEST.UPDATED, payload: test });
    dispatch(setAlert("Test has been Deactivated successfully."));
  } catch (error) {
    console.log(error);
  }
};

// Edit Test
export const editTest = (test) => async (dispatch) => {
  dispatch({ type: TEST.SAVING, payload: true });

  try {
    await axios.put(`/api/test/${test._id}/status`, JSON.stringify({ status: TEST_STATUS_EDIT }));

    dispatch({ type: TEST.UPDATED, payload: { ...test, status: TEST_STATUS_EDIT } });
  } catch (error) {
    console.log(error);
  }
};

// Delete Test
export const deleteTest = (testId) => async (dispatch) => {
  // dispatch({ type: TEST.LOADING, payload: true });
  try {
    await axios.put(`/api/test/${testId}/status`, { status: TEST_STATUS_DELETED });

    dispatch({ type: TEST.DELETED, payload: testId });
    dispatch(setAlert("Test deleted successfully.", "success"));
  } catch (error) {
    console.log(error);
  }
};

// Duplicate Test
export const duplicateTest = (testId) => async (dispatch) => {
  dispatch({ type: TEST.LOADING, payload: true });

  try {
    const res = await axios.post(`/api/test/${testId}/duplicate`);
    dispatch({ type: TEST.DUPLICATED, payload: res.data });
    dispatch(setAlert("Test has been Duplicated successfully.", "success"));
    return res.data._id; // Return TestId
  } catch (error) {
    console.log(error);
  }
};

// Duplicate Test2
export const duplicateTest2 = async (testId) => {
  try {
    const res = await axios.post(`/api/test/${testId}/duplicate`);
    return res.data;
  } catch (error) {
    return error;
  }
};

// Test Saving
export const testSaving = (isSaving) => (dispatch) => dispatch({ type: TEST.SAVING, payload: isSaving });
