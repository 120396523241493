import { isMobile } from "react-device-detect";
import { useAuthContext } from "./AuthProvider";
// import { redirectToYii } from "../../utills/common";
import PropTypes from "prop-types";
import UXLoader from "../layouts/UXLoader";
import UXMObileView from "../layouts/UXMobleView";

AuthGuard.propTypes = { children: PropTypes.node };

export default function AuthGuard({ children }) {
  const { isAuthenticated, loading } = useAuthContext();

  if (!isAuthenticated && loading) return <UXLoader />;
  // if (!isAuthenticated && ((error && !error?.noAccount) || !loading)) return redirectToYii();
  if (isMobile) return <UXMObileView />;

  return <> {children} </>;
}
