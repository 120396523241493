import { Avatar, Typography } from "antd";
import { useAuthContext } from "../auth/AuthProvider";

const { Paragraph } = Typography;

export default function UserAvatar() {
  const { user } = useAuthContext();
  const { email = "", fullName = "" } = user || {};

  return (
    <div className='p-2 d-flex align-items-center gap-2 ux-avatar font-size-16px montserrat'>
      <Avatar className='bg-blue-1 font-weight-bold capitalize' size={36}>
        {fullName.charAt(0)}
      </Avatar>

      <div style={{ maxWidth: "calc(100% - 36px - 1rem)" }}>
        <Paragraph strong className='m-0 montserrat'>
          {fullName}
        </Paragraph>
        <Paragraph strong type='secondary' className='m-0 font-size-12px montserrat'>
          {email}
        </Paragraph>
      </div>
    </div>
  );
}
